import React, { FunctionComponent } from "react";

export const Plus = () => (
  <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1400_30023)">
      <path fill="currentColor" d="M16 0C16.5304 0 17.0391 0.210714 17.4142 0.585787C17.7893 0.96086 18 1.46957 18 2V14H30C30.5304 14 31.0391 14.2107 31.4142 14.5858C31.7893 14.9609 32 15.4696 32 16C32 16.5304 31.7893 17.0391 31.4142 17.4142C31.0391 17.7893 30.5304 18 30 18H18V30C18 30.5304 17.7893 31.0391 17.4142 31.4142C17.0391 31.7893 16.5304 32 16 32C15.4696 32 14.9609 31.7893 14.5858 31.4142C14.2107 31.0391 14 30.5304 14 30V18H2C1.46957 18 0.96086 17.7893 0.585787 17.4142C0.210714 17.0391 0 16.5304 0 16C0 15.4696 0.210714 14.9609 0.585787 14.5858C0.96086 14.2107 1.46957 14 2 14H14V2C14 1.46957 14.2107 0.96086 14.5858 0.585787C14.9609 0.210714 15.4696 0 16 0Z"/>
    </g>
    <defs>
      <clipPath id="clip0_1400_30023">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
