import { text } from "node:stream/consumers";
import React from "react";
import logo from "../../assets/images/logo-dark-bg.png";

interface HeroProps {
  children?: JSX.Element;
  heading: string;
  description: string;
}

export const Hero = ({ heading, description, ...props }: HeroProps) => (
  <>
    <div className="relative flex w-full items-center justify-center bg-primary">
      <div className="h-9/12 flex w-9/12 justify-center p-4 lg:max-w-xl lg:p-16">
        <img
          src={logo}
          // Optimization test: set width/height to desktop max. Helps with CLS and improves vanity metrics in Lighthouse.
          style={{ width: "448px", height: "212px" }}
          className="object-fit !h-full"
          alt="Huggies PopoScan Logo"
        />
      </div>
    </div>
    <div className="px-4 py-4 text-center lg:py-12 lg:px-[300px]">
      <h1 className="mb-4 font-serif text-heading-1-mobile font-bold lg:text-heading-1-desktop">
        {heading}
      </h1>
      <p className="text-lg lg:text-xl">{description}</p>
    </div>
  </>
);
