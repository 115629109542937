export const loading = {
  generic: "Cargando",
  analyzing: "Analizando tu imagen",
  analyzingNoImage: "Analizando"
};

export const errors = {
  generalServerError: "Algo salió mal...",
  noImageContingencyRefreshError: "Lo sentimos, intenta de nuevo..."
}

// NOTE: This is also hard-coded in public/index.html and public/manifest.json
export const title = "NUEVO Huggies® Popó Scan | Powered by Más Abrazos";

export const hero = {
  heading: "Nuevo Huggies® Popó Scan",
  description:
    "Huggies® Popó Scan proporciona instantáneamente información valiosa en tiempo real basada en el color del popó de tu bebé.",
};

export const headers = {
  label: "Paso",
  step1: {
    heading: "Algunas Preguntas Rápidas",
    description: "¡Tus respuestas nos ayudarán a brindarte comentarios más precisos!",
  },
  step2: {
    heading: "Agrega una foto del pañal sucio de tu bebé",
    description: "No tires ese pañal sucio, toma una foto y súbela a Huggies® Popó Scan.",
  },
  step2NoImage: {
    heading: "¿No tienes un pañal sucio? ¡No hay problema!",
    description: "Por favor selecciona el color más cercana al popó de tu bebé.",
  },
  step3: {
    heading: "Selecciona el Color, la Consistencia y la Textura",
    description:
      "Por favor selecciona el color, la consistencia y la textura más cercana al popó de tu bebé.",
  },
  step3Confirm: {
    heading: "¡Ya casi lo tienes!",
    description:
      "¡Estás cerca de entender el popó de tu bebé! Déjanos saber a donde enviar tus resultados. Te enviaremos una copia de estos para que lo conserves.",
  },
};

export const labelConsistency = "Selecciona la Consistencia";
export const labelTexture = "Selecciona la Textura (opcional)";

export const consistency = [
  {
    label: "Líquida",
    icon: "loose",
  },
  {
    label: "Blanda",
    icon: "soft",
  },
  {
    label: "Dura",
    icon: "hard",
  },
];

export const texture = [
  {
    label: "Grumosa",
    icon: "seedy",
  },
  {
    label: "Pegajosa",
    icon: "sticky",
  },
  {
    label: "Mucosa",
    icon: "mucus",
  },
  {
    label: "Partículas",
    icon: "flecks",
  },
];

export const footers = {
  step1: {
    footer:
      "Apto para bebés hasta los 24 meses.<br>Para continuar por favor completa todos los campos solicitados y acepta los términos y condiciones de uso.",
  },
  step2: {
    footer:
      "NOTA: Los consejos están destinados a brindar una guía general basada únicamente en el color de las heces de tu bebé y no deben considerarse como un consejo médico. Si tu bebé presenta otros síntomas o no estás seguro de algo, habla con tu profesional de la salud lo antes posible.",
  },
  step3: {
    footer:
      "NOTA: Los consejos están destinados a brindar una guía general basada únicamente en el color de las heces de tu bebé y no deben considerarse como un consejo médico. Si tu bebé presenta otros síntomas o no estás seguro de algo, habla con tu profesional de la salud lo antes posible.",
  },
  step3Confirm: {
    footer:
      "NOTA: Los consejos están destinados a brindar una guía general basada únicamente en el color de las heces de tu bebé y no deben considerarse como un consejo médico. Si tu bebé presenta otros síntomas o no estás seguro de algo, habla con tu profesional de la salud lo antes posible.",
  },
};
