/* eslint-disable camelcase */
import { colorMap } from "./colorMap";

export const colorEnglishToSpanish = (english) => {
  const { spanish } = colorMap.find((color) => color.id === english);
  return spanish;
};

export const textEnglishToSpanish = (english) => {
  const { id_spanish } = colorMap.find((color) => color.id === english);
  return id_spanish;
};
