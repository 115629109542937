import React from "react";

interface FormLabelProps {
  children?: JSX.Element;
  text: string;
  htmlFor?: string;
  className?: string | undefined;
}

export const FormLabel = ({ text, htmlFor, className = "", ...props }: FormLabelProps) => (
  <label
    htmlFor={htmlFor}
    className={`${className} block text-left text-body-mobile-input lg:text-body-desktop`}
  >
    {text}
  </label>
);
