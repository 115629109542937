import React, { useState, useRef, useMemo, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import Draggable from "react-draggable";
import { ColorExtractor } from "react-color-extractor";
import getCanvasPixelColor from "get-canvas-pixel-color";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel } from "../../FormLabel/FormLabel";
import { FormError } from "../../FormError/FormError";
import refreshIcon from "../../../assets/images/icon-refresh.svg";
import { StoolIcon } from "../../StoolIcons/StoolIcon";
import { Checkmark } from "../../Checkmark/Checkmark";
import { Button } from "../../Button/Button";
import { fireGaEvent } from "../../../utils/helpers/analytics";
import { Step3FormValidationProps, Step3Props } from "../../../pages/Props";
import { string } from "prop-types";

export interface Coordinates {
  x: number;
  y: number;
}

const topHelperLeft = "Selecciona el Color";
const topHelperRight = "Reemplazar imagen";
const selectorTooltip = "Usa el Selector de color para seleccionar el popó en la imagen";
const bottomHelper = "Toca las muestras de color para seleccionar";
const previewText = "Vista previa de color";
const labelConsistency = "Selecciona la Consistencia";
const labelTexture = "Selecciona la Textura (opcional)";
const buttonText = "Confirmar";

const errorTextCrosshairs = "Por favor utiliza el selector de color para escoger el color del popó en la imagen"
const errorTextSelectedColor = "Por favor selecciona un bloque de color para proceder";
const errorTextConsistency = "Por favor selecciona la consistencia";
const errorTextTexture = "Por favor selecciona la textura";

const consistency = [
  {
    label: "Líquida",
    icon: "loose",
  },
  {
    label: "Blanda",
    icon: "soft",
  },
  {
    label: "Dura",
    icon: "hard",
  },
];
const texture = [
  {
    label: "Grumosa",
    icon: "seedy",
  },
  {
    label: "Pegajosa",
    icon: "sticky",
  },
  {
    label: "Mucosa",
    icon: "mucus",
  },
  {
    label: "Partículas",
    icon: "flecks",
  },
];

const selectedClasses =
  "group flex flex-col justify-center text-center gap-2 py-2 px-[6.5px] bg-primary text-white border border-grayscale-3 rounded text-body-mobile lg:font-bold";
const defaultClasses =
  "group flex flex-col justify-center text-center gap-2 py-2 px-[6.5px] bg-white border rounded text-body-mobile lg:font-bold border-grayscale-3 hover:bg-peach hover:cursor-pointer hover:border-primary";

export const Step3 = ({ stepsSwitch, setStepsSwitch, selectedImage, setStep3FormData}: Step3Props) => {
  const draggableRef = useRef(null);
  const sourceImageRef = useRef<any>(null);
  const crosshairsWidth = 40; // Tailwind w-8 is 32px (Figma 32 mobile), w-16 is 64px (Figma 60px desktop), Element is 40.

  // Set initial coordinates
  // Used to set initial location of draggable element
  const [coordinates, setCoordinates] = useState<Coordinates>({
    x: 150,
    y: 150,
  });
  const originalImageMemo = useMemo(() => URL.createObjectURL(selectedImage!), [selectedImage]);
  const [imageDataForCrosshairsPreview, setImageDataForCrosshairsPreview] = useState<string>();
  const [imageDataForDigUpload, setImageDataForDigUpload] = useState<string>();
  const [extractedColors, setExtractedColors] = useState<string[]>([]);
  const [centerPixelColor, setCenterPixelColor] = useState<string | undefined>(); // Center of crosshairs - single pixel

  // NOTE: This step does NOT use formik for validation - it uses a custom hack to show the proper error messsages
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [showCrosshairsErrorMessage, setShowCrosshairsErrorMessage] = useState(false);
  const [showBlocksErrorMessage, setShowBlocksErrorMessage] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState<number | undefined>(); // Sets default checkbox.
  const [selectedConsistency, setSelectedConsistency] = useState<string>();
  const [selectedTexture, setSelectedTexture] = useState<string>();

  const schema  = yup
    .object({
      consistency: yup.string(),// .required(errorTextConsistency),
      texture: yup.string(),// .required(errorTextTexture),
      selectedColor: yup.string().required(errorTextSelectedColor),
    })
    .required();

  const initialValues = {
    // previewImageData: "",
    selectedColor: "",
    consistency: "",
    texture: "",
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit(values) {
      buttonHandlerForward(values)
    },
  })

  // Show crosshairs tooltip on page load
  useEffect(() => {
    if (draggableRef?.current) {
      ReactTooltip.show(draggableRef.current);
    }
  }, [extractedColors]);

  // Anytime the color picker coordinates change...
  // ...keep the crosshairs preview image updated
  useEffect(() => {
    if (!coordinates) {
      return;
    }

    // Get the source image element element
    const sourceImage = document.getElementById("selected-image") as HTMLImageElement;

    // Define the canvas
    const destinationCanvas = document.getElementById("crosshairs-canvas") as HTMLCanvasElement;
    const context = destinationCanvas.getContext("2d", {willReadFrequently: true});

    // Define source image top-left coordinates
    // Factor in width of crosshairs circle element
    const topLeftCoordinates: Coordinates = {
      x: coordinates.x - crosshairsWidth / 2,
      y: coordinates.y + crosshairsWidth / 2,
    };

    // Get the scale of current div width to original image width
    // eslint-disable-next-line no-unsafe-optional-chaining
    const ratio = sourceImageRef?.current?.naturalWidth / sourceImageRef?.current?.width;

    // Docs for drawImage API: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
    context?.drawImage(
      sourceImage,
      topLeftCoordinates.x * ratio, // source x position from top left
      topLeftCoordinates.y * ratio, // source y position from top left
      crosshairsWidth * ratio, // source width
      crosshairsWidth * ratio, // source height
      0, // destination x position from top left
      0, // destination y position from top left
      crosshairsWidth, // destination width
      crosshairsWidth, // destination height
    );

    // Get the data from destination image we just drew on the canvas
    const dataFromDrawnImage = destinationCanvas.toDataURL("image/png");

    setImageDataForCrosshairsPreview(dataFromDrawnImage);
  }, [coordinates]);

  // Update the coordinates when visitor drags the circle
  // Factors in the width of the draggable crosshairs
  const onDrag = (e: any, ui: Coordinates) => {
    // Hide the helper Tooltip
    ReactTooltip.hide(draggableRef.current!);

    // Hide the Error Message (if exists)
    setShowCrosshairsErrorMessage(false);

    // Show preview color swatches
    setUserHasInteracted(true)

    // Update preview color
    const canvas = document.getElementById("crosshairs-canvas");
    const { rgb } = getCanvasPixelColor(canvas, crosshairsWidth / 2, crosshairsWidth / 2); // returns an array/object: rgb(0,0,0)
    setCenterPixelColor(rgb);

    // Adjust selectedCoordinates depending on how image layout out in responsive design
    const adjustedCoordinates = {
      x: ui.x + crosshairsWidth / 2,
      y: ui.y - crosshairsWidth / 2,
    };

    // Update the coordinates
    setCoordinates(adjustedCoordinates);
  };

  // Draw a white circle on the preview canvas
  // Save to state for upload to Dig alongside form payload
  const onStop = (e, ui) => {
    // The original, full image as presented (aka its not the full sized image)
    const sourceImage = document.getElementById("selected-image") as HTMLImageElement;

    // Draw the preview on a canvas so we can save the image with the preview circle
    // Use {willReadFrequently: true} as a side-experiment for performance => forces browser to use software instead of accelerated graphics
    const destinationCanvas = document.getElementById("destination-canvas") as HTMLCanvasElement;

    const previewImageContext = destinationCanvas.getContext("2d", {willReadFrequently: true});

    // Note: This drawImage function does NOT require adjusting for aspect ratio distortion due to responsive design..
    // ...see the useEffect version of this function that includes a "ratio" definition and its subsequent use

    // Define the white circle to be drawn over the image
    if (previewImageContext) {
      // Draw the base image on the canvas
      previewImageContext?.drawImage(
        sourceImage,
        0, // source x position from top left
        0, // source y position from top left
        sourceImageRef.current?.naturalWidth, // source width
        sourceImageRef.current?.naturalHeight, // source height
        0, // destination x position from top left
        0, // destination y position from top left
        sourceImageRef.current?.clientWidth, // destination width
        sourceImageRef.current?.clientHeight, // destination height
      );

      // Place the circle on the base image
      previewImageContext.beginPath();
      // arc(x, y, radius, startAngle, endAngle, counterclockwise);
      previewImageContext?.arc(
        coordinates.x,
        coordinates.y + crosshairsWidth,
        crosshairsWidth / 2,
        0,
        2 * Math.PI,
        true,
      );
      // previewImageContext.arc(20, 20, 20, 0, 2 * Math.PI);
      // previewImageContext?.arc(100, 75, 50, 0, 2 * Math.PI);
      previewImageContext.lineWidth = 3;
      previewImageContext.strokeStyle = "#fff";
      previewImageContext.stroke();

      // Capture the image and set to state for later upload
      const previewImageData = destinationCanvas.toDataURL();
      setImageDataForDigUpload(previewImageData);
    }
  };

  const extractAndSetColors = (colors: string[]) => {
    setExtractedColors(colors);
  };

  const handleColorSelection = async (event) => {
    const value = parseInt(event.target.value, 10);
    const color = event.target.parentElement.style.backgroundColor;
    // const rgbArr = color.substring(4, color.length-1).replace(/ /g, "").split(",");
    if (value) {
      setSelectedRadio(value);
      setShowBlocksErrorMessage(false);
      await formik.setFieldValue("selectedColor", color);
    }
  };

  const buttonHandlerBack = () => {
    fireGaEvent(window, "clickEvent", "step-3_loadNewImage", 10);
    setStepsSwitch("Step2");
  };

  const buttonHandlerForward = (data: Step3FormValidationProps) => {
    // Custom error handling - NOT using Formik
    if (userHasInteracted && selectedRadio) {
      setStep3FormData({
        colors: extractedColors,
        colorInput: formik?.values?.selectedColor ?? centerPixelColor, // TODO Add user selector color, if exists. Else, default to center of cross hairs
        regionOfInterestCoordinates: Object.values(coordinates),
        roiPreviewImage: imageDataForDigUpload, // This requires a user to move the crosshairs
        consistency: selectedConsistency,
        texture: selectedTexture,
      });
      fireGaEvent(window, "clickEvent", "step-3_submit", 10);
      setStepsSwitch("Step3Confirm");
    } else if (!userHasInteracted) {
      setShowCrosshairsErrorMessage(true);
      ReactTooltip.show(draggableRef.current!);
    } else if (userHasInteracted && !selectedRadio) {
      setShowBlocksErrorMessage(true);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="bg-peach px-4 py-6 lg:px-[300px] lg:py-12">
        <div className="wrapper">
          <div className="flex justify-between">
            <div className="text-body-mobile lg:text-body-desktop">{topHelperLeft}</div>
            <div
              onClick={buttonHandlerBack}
              className="flex items-center text-body-mobile-small text-primary lg:text-body-desktop-small"
            >
              <img className="mr-1 h-4 w-4" src={refreshIcon} alt="Icon to replace image" />
              {topHelperRight}
            </div>
          </div>
          <div className="flex flex-col justify-center gap-1 lg:flex-row">
            <div id="image-display-container" className="relative mt-[9px] lg:bg-white">
              <ReactTooltip
                type="light"
                effect="solid"
                className="!w-52 !rounded-lg bg-white !p-4 !text-body-desktop-small !opacity-100 !shadow-xl lg:!w-72 lg:!text-body-desktop"
              >
                {selectorTooltip}
              </ReactTooltip>
              <Draggable
                defaultPosition={{ x: coordinates.x, y: coordinates.y }}
                defaultClassName="z-10 absolute top:50% left:50%"
                onDrag={onDrag}
                onStop={onStop}
                nodeRef={draggableRef}
                bounds="#selected-image"
              >
                <div
                  id="draggable"
                  ref={draggableRef}
                  data-tip={selectorTooltip}
                  data-show={true}
                  data-scroll-hide={true}
                  className="absolute flex items-center justify-center rounded-1/2 border-2 border-white text-white drop-shadow"
                  style={{ width: `${crosshairsWidth}px`, height: `${crosshairsWidth}px` }}
                >
                  <span>+</span>
                </div>
              </Draggable>

              {/* On load, extract colors from full image */}
              <ColorExtractor getColors={extractAndSetColors} maxColors={10}>
                <img
                  id="selected-image"
                  className="object-fit relative z-0 h-full w-full"
                  src={originalImageMemo} // Dig sets this from State
                  ref={sourceImageRef}
                  alt="Uploaded image"
                />
              </ColorExtractor>
              <ColorExtractor
                src={
                  imageDataForCrosshairsPreview ? imageDataForCrosshairsPreview : originalImageMemo
                }
                getColors={extractAndSetColors}
                maxColors={10}
              />
              {showCrosshairsErrorMessage && 
                <FormError text={errorTextCrosshairs} />
              }
              {showBlocksErrorMessage && 
                <FormError text={errorTextSelectedColor} />
              }
            </div>

            <div>
              <canvas
                id="crosshairs-canvas"
                width={crosshairsWidth}
                height={crosshairsWidth}
                style={{ display: "none" }}
                className="border-2 border-blue-500"
              />
              <canvas
                id="destination-canvas"
                width={sourceImageRef.current?.clientWidth ?? 0}
                height={sourceImageRef.current?.clientHeight ?? 0}
                style={{ display: "none" }}
                className="border-2 border-pink-500"
              />
            </div>

            {/* Extracted Colors Preview */}
            {userHasInteracted &&
              <>
                <div className="grid grid-cols-4 rounded-md lg:mt-[9px] lg:grid-cols-1">
                  {/* Loop through the colors extracted by react-color-extractor and show the first 3 */}
                  {/* IMPORTANT - map index is offset by one as index "0" caused side-effect */}
                  {extractedColors.slice(0, 3).map((color, index) => (
                    <div
                      key={index + 1}
                      className={`flex h-12 items-center justify-center text-transparent lg:h-full lg:w-[90px] ${showBlocksErrorMessage ? "lg:border lg:border-secondary" : ""}`}
                      style={{ backgroundColor: color }}
                    >
                      <label
                        className={`flex h-1/2 w-1/2 items-center justify-center peer-checked:flex ${
                          selectedRadio === (index + 1) ? "text-white" : "transparent"
                        }`}
                        htmlFor={`selectedColor-${index + 1}`}
                      >
                        <Checkmark />
                      </label>
                      <input
                        type="radio"
                        value={index + 1}
                        className="peer hidden"
                        name="selectedColor"
                        id={`selectedColor-${index + 1}`}
                        checked={selectedRadio === (index + 1)}
                        onChange={handleColorSelection}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  ))}

                  {/* Use the last square to show the single pixel color preview - this will be the default selected square */}
                  <div
                    className={`flex h-12 items-center justify-center text-transparent lg:h-full lg:w-[90px] ${showBlocksErrorMessage ? "lg:border lg:border-secondary" : ""}`}
                    style={{ backgroundColor: `${centerPixelColor}` }}
                  >
                    <label
                      className={`flex h-1/2 w-1/2 items-center justify-center peer-checked:flex ${
                        selectedRadio === 4 ? "text-white" : "transparent"
                      }`}
                      htmlFor={`selectedColor-${4}`}
                    >
                      <Checkmark />
                    </label>
                    <input
                      type="radio"
                      value={4}
                      className="peer hidden"
                      name="selectedColor"
                      id={`selectedColor-${4}`}
                      checked={selectedRadio === 4}
                      onChange={handleColorSelection}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  <div className="hidden flex-col justify-end lg:flex">
                    {/* Desktop PreviewImage */}
                    <div className="rounded-2 mt-6 flex lg:h-12 lg:w-[90px]">
                      <img src={imageDataForCrosshairsPreview} className="grow object-fill" alt="" />
                    </div>

                    <div className="mt-2 text-body-mobile-small">{previewText}</div>
                  </div>
                </div>
                <div className="text-body-mobile-small lg:hidden">{bottomHelper}</div>
              </>
            }
          </div>

          {/* TODO Delete this troubleshooting div */}
          {/* <div className="todo-delete-this-troubleshooting-div flex items-center">
            <img src={imageDataForCrosshairsPreview} alt="" /> 👈 Image used for color extraction
          </div>
          <div className="todo-delete-this-troubleshooting-div flex items-center">
            <div className="h-10 w-10" style={{ backgroundColor: `${centerPixelColor}` }} /> 👈 Color implied
            from single pixel
          </div>
          <div className="todo-delete-this-troubleshooting-div mt-4 flex flex-col items-center">
            <div>👇 Image with drawn ROI for Dig API</div>
            <img src={imageDataForDigUpload} alt="" />
          </div> */}
        </div>

        {formik.touched.selectedColor && formik.errors.selectedColor ? (
          // <FormError text={formik.errors.selectedColor} />
          <FormError text="Please select the color swatch to proceed" />
        ) : null}

        <div className="flex flex-col gap-y-6 md:px-5 py-9 lg:flex-row lg:justify-between lg:px-0">
          {/* Consistency */}
          <div>
            <FormLabel text={labelConsistency} className="p-1" />
            <div className="max-full grid grid-cols-4 justify-between gap-x-2 self-center lg:grid-cols-3 lg:gap-2">
              {consistency.map(({ label, icon }) => (
                <div
                  key={label}
                  onClick={async () => {
                    setSelectedConsistency(icon);
                    formik.handleBlur(icon);
                    formik.handleChange(icon);
                    fireGaEvent(window, "clickEvent", `step-3_consistency-${icon}`, 10);
                    await formik.setFieldValue("consistency", icon);
                  }}
                  className={`${selectedConsistency === icon ? selectedClasses : defaultClasses}`}
                >
                  <StoolIcon isSelected={selectedConsistency === icon} icon={icon} />
                  {label}
                </div>
              ))}
            </div>
            {formik.touched.consistency && formik.errors.consistency ? (
              <FormError text={formik.errors.consistency} />
            ) : null}
          </div>

          {/* Texture */}
          <div>
            <FormLabel text={labelTexture} className="p-1" />
            <div className="max-full grid grid-cols-4 justify-between gap-x-2 self-center lg:gap-2">
              {texture.map(({ label, icon }) => (
                <div
                  key={label}
                  onClick={async () => {
                    setSelectedTexture(icon);
                    formik.handleBlur(icon);
                    formik.handleChange(icon);
                    fireGaEvent(window, "clickEvent", `step-3_texture-${icon}`, 10);
                    await formik.setFieldValue("texture", icon);
                  }}
                  className={`${selectedTexture === icon ? selectedClasses : defaultClasses}`}
                >
                  <StoolIcon isSelected={selectedTexture === icon} icon={icon} />
                  {label}
                </div>
              ))}
            </div>
            {formik.touched.texture && formik.errors.texture ? (
              <FormError text={formik.errors.texture} />
            ) : null}
          </div>
        </div>
        <div className="flex justify-center gap-x-9 text-center">
          <Button variant="primary" type="button" text={buttonText} onClick={buttonHandlerForward} />
        </div>
      </div>
    </form>
  );
};
