import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel } from "../../FormLabel/FormLabel";
import { FormError } from "../../FormError/FormError";
import { PoopIcon } from "../../PoopIcon/PoopIcon";
import { StoolIcon } from "../../StoolIcons/StoolIcon";
import { Button } from "../../Button/Button";
import { fireGaEvent } from "../../../utils/helpers/analytics";
import { colorMap } from "../../../utils/helpers/colorMap";
import { consistency, labelConsistency, texture, labelTexture } from "../../../utils/constants/es-PE/all";
import { Step3FormValidationProps, Step3Props, Step2NoImageProps } from "../../../pages/Props";

const topHelperLeft = "Selecciona el Color más Cercano"; // Seleccione un color típico // Seleccione el Color Más Cercano (TODO typical color?)
const buttonText = "Confirmar";
const errorTextSelectedColor = "Por favor selecciona un bloque de color para proceder";
const errorTextConsistency = "Por favor selecciona la consistencia";
const errorTextTexture = "Por favor selecciona la textura";

// Consistency & Texture select classes
const selectedClasses =
  "group flex flex-col justify-center text-center gap-2 py-2 px-[6.5px] bg-primary text-white border border-grayscale-3 rounded text-body-mobile lg:font-bold";
const defaultClasses =
  "group flex flex-col justify-center text-center gap-2 py-2 px-[6.5px] bg-white border rounded text-body-mobile lg:font-bold border-grayscale-3 hover:bg-peach hover:cursor-pointer hover:border-primary";

export const Step2NoImage = ({ stepsSwitch, setStepsSwitch, setStep3FormData}: Step2NoImageProps) => {
  const [imageDataForDigUpload, setImageDataForDigUpload] = useState<string>();
  const [extractedColors, setExtractedColors] = useState<string[]>([]);
  const [centerPixelColor, setCenterPixelColor] = useState<string | undefined>(); // Center of crosshairs - single pixel

  // NOTE: This step does NOT use formik for validation - it uses a custom hack to show the proper error messsages
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string | undefined>(); // Sets default checkbox.
  const [selectedConsistency, setSelectedConsistency] = useState<string>();
  const [selectedTexture, setSelectedTexture] = useState<string>();

  const schema  = yup
    .object({
      consistency: yup.string(),// .required(errorTextConsistency),
      texture: yup.string(),// .required(errorTextTexture),
      selectedColor: yup.string().required(errorTextSelectedColor),
    })
    .required();

  const initialValues = {
    // previewImageData: "",
    selectedColor: "",
    consistency: "",
    texture: "",
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit(values) {
      buttonHandlerForward(values)
    },
  })

  // const extractAndSetColors = (colors: string[]) => {
  //   setExtractedColors(colors);
  // };

  const buttonHandlerBack = () => {
    fireGaEvent(window, "clickEvent", "step-2_no-image_backToStep1", 15); // TODO Confirm w/ Carlos
    setStepsSwitch("Step1");
  };

  const buttonHandlerForward = (data: Step3FormValidationProps) => {
    console.log("Color has been selected");
    if (selectedColor) {
      console.log(`The selected color is ${selectedColor}`);
      setStep3FormData({
        colors: [],// extractedColors, // TODO No Image this will be null
        // TODO No Image final flow might change the below
        colorInput: selectedColor, // TODO This is now a string. Ensure this flows through entire payload submit sequence.
        // regionOfInterestCoordinates: Object.values(coordinates), // TODO No Image this will be null
        // roiPreviewImage: imageDataForDigUpload, // This requires a user to move the crosshairs // TODO No Image this will be null
        consistency: selectedConsistency,
        texture: selectedTexture,
      });
      fireGaEvent(window, "clickEvent", "step-2_no-image_submit", 10);
      setStepsSwitch("Step3Confirm");
    } else {
      // TODO Show an error message
      // setShowBlocksErrorMessage(true);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="bg-peach px-4 py-6 lg:px-[300px] lg:py-12">
        <div className="wrapper">
          <div className="flex justify-between lg:justify-center">
            <FormLabel text={topHelperLeft} className="p-1" />
          </div>
          <div className="flex flex-col pt-4 pb-7 justify-between grow gap-1 lg:flex-row lg:justify-center">
            <div
              className="max-full grid grid-cols-3 justify-between gap-x-2 gap-y-2 self-center lg:grid-cols-3 lg:gap-2"
            >
              {/* TODO Min-width of 100px in emoji component throws off flexbox spacing if grid cols is 4, not 3 */}
              {colorMap.map(({ id, hex, spanish }) => (
                <div
                  key={id}
                  id={id}
                  className={`flex w-fit flex-col items-center bg-white py-1.5 text-body-mobile lg:font-bold rounded border-[3px] ${selectedColor === id ? "border-secondary" : "border-transparent" }`}
                  onClick={async (e) => {
                    setSelectedColor(id);
                    formik.handleBlur(id);
                    formik.handleChange(id);
                    await formik.setFieldValue("selectedColor", id);
                    fireGaEvent(window, "clickEvent", `step-2_no-image_selected-color-${id}`, 10); // TODO Confirm w/ Carlos
                  }}
                >
                  <div>
                    <PoopIcon color={hex} variant="white-bg" />
                  </div>
                  <span className="mt-1 mb-1.5 min-w-[100px] text-center lg:min-w-[120px]">
                    {spanish}
                  </span>
                </div>
              ))}
            </div>
            {formik.touched.selectedColor && formik.errors.selectedColor ? (
              <FormError text={formik.errors.selectedColor} />
              // <FormError text="Please select the color swatch to proceed" />
            ) : null}
          </div>
        </div>

        {/* <div className="flex flex-col gap-y-6 md:px-5 py-9 lg:flex-row lg:justify-between lg:px-0"> */}
        {/* Consistency */}
        {/* <div>
          <FormLabel text={labelConsistency} className="p-1" />
          <div className="max-full grid grid-cols-4 justify-between gap-x-2 self-center lg:grid-cols-3 lg:gap-2">
            {consistency.map(({ label, icon }) => (
              <div
                key={label} // eslint-disable-next-line @typescript-eslint/brace-style
                onClick={async () => {
                  setSelectedConsistency(icon);
                  formik.handleBlur(icon);
                  formik.handleChange(icon);
                  fireGaEvent(window, "clickEvent", `step-3_consistency-${icon}`, 10);
                  await formik.setFieldValue("consistency", icon);
                }}
                className={`${selectedConsistency === icon ? selectedClasses : defaultClasses}`}
              >
                <StoolIcon isSelected={selectedConsistency === icon} icon={icon} />
                {label}
              </div>
            ))}
          </div>
          {formik.touched.consistency && formik.errors.consistency ? (
            <FormError text={formik.errors.consistency} />
          ) : null}
        </div> */}

        {/* Texture */}
        {/* <div>
            <FormLabel text={labelTexture} className="p-1" />
            <div className="max-full grid grid-cols-4 justify-between gap-x-2 self-center lg:gap-2">
              {texture.map(({ label, icon }) => (
                <div
                  key={label}
                  onClick={async () => {
                    setSelectedTexture(icon);
                    formik.handleBlur(icon);
                    formik.handleChange(icon);
                    fireGaEvent(window, "clickEvent", `step-3_texture-${icon}`, 10);
                    await formik.setFieldValue("texture", icon);
                  }}
                  className={`${selectedTexture === icon ? selectedClasses : defaultClasses}`}
                >
                  <StoolIcon isSelected={selectedTexture === icon} icon={icon} />
                  {label}
                </div>
              ))}
            </div>
            {formik.touched.texture && formik.errors.texture ? (
              <FormError text={formik.errors.texture} />
            ) : null}
          </div> */}
        {/* </div> */}
        <div className="flex justify-center gap-x-9 text-center">
          <Button variant="primary" type="submit" text={buttonText} />
        </div>
      </div>
    </form>
  );
};
