import React, { useState, useEffect, useCallback, useRef } from "react";
import { debounce } from "lodash-es";
import { useNavigate } from "react-router-dom";
import { PoopIcon } from "../PoopIcon/PoopIcon";
import { Heading } from "../Headings/Heading";
import { RecommendationCard } from "../RecommendationCard/RecommendationCard";
import { ExpandableText } from "../ExpandableText/ExpandableText";
import { Button } from "../Button/Button";
import { ErrorRedirect } from "../Error";
import { fireGaEvent } from "../../utils/helpers/analytics";
import { colorEnglishToSpanish } from "../../utils/helpers/translate";
import { getHexColor } from "../../utils/helpers/getHexColor";
import { title, errors } from "../../utils/constants/es-PE/all";
import { AnalysisReturnProps } from "../../pages/Props";
import { ReactComponent as IconWhatsapp}  from "../../assets/images/icons/whatsapp.svg";
import { ReactComponent as IconFacebook}  from "../../assets/images/icons/facebook.svg";
import { ReactComponent as IconShare}  from "../../assets/images/icons/share-fill.svg";

interface CompletedStepsProps {
  analysis: AnalysisReturnProps;
  setAnalysis: (analysis: AnalysisReturnProps) => void;
  hasNoImage?: boolean;
}

const buttonSecondaryText = "Subir una Foto";
const buttonPrimaryText = "Comparte tus Resultados";
const buttonPrimaryTextNoImage = "Comparte la Aplicación"
const shareTitle = title;
const shareTextBody = "Comparte los resultados con tu pareja o proveedor de salud guardando el enlace para WhatsApp o cualquier otra plataforma";
const shareTextBodyNoImage = "Comparte la aplicación con tu pareja o proveedor de salud guardando el enlace para WhatsApp o cualquier otra plataforma";
const shareSuccessMsg = "Mensaje enviado";

const recommendationsHeading = "¿Quieres saber más?";
const recommendationsDescription =
  "¡Obtén información con nuestra lista personalizada de artículos, recursos y productos!";
const invalidStateMsg = errors.generalServerError;

export const Recommendations = ({ analysis, setAnalysis, ...props }: CompletedStepsProps) => {
  /**
   * The visibleColor state drives both the visible poop emoji as well as the content
   */
  const { colorPrediction, colorRecommendations, nonColorRecommendations } = analysis;
  const [visibleColor, setVisibleColor] = useState<string>();
  const isFirstRender = useRef(true);
  const navigate = useNavigate();

  // Set defaults as needed after page is loaded
  useEffect(() => {
    if (!visibleColor && colorPrediction) {
      // Set initial content
      setVisibleColor(colorPrediction);

      // Center target poop emoji in container
      const refToInitialTargetEl = document.getElementById(colorPrediction);
      if (refToInitialTargetEl) {        
        // Setting behavior: smooth is best for mobile - has a nice scroll effect
        // But desktop requires behavior: auto as the smooth scroll takes too long
        // If a user tries to vertically scroll the page before the scrollIntoView effect has finished it gets cancelled
        const behavior = window.screen.width >= 1024 ? "auto" : "smooth";
        refToInitialTargetEl.scrollIntoView({
          behavior,
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [colorPrediction]);

  // Register GA event on poop emoji engagement - scroll event
  // Ensure event is NOT triggered on initial load - only on user scroll
  useEffect(() => {
    if (visibleColor && isFirstRender.current) {
      isFirstRender.current = false;
    } else if (visibleColor) {
      fireGaEvent(window, "clickEvent", `results_emoji-${visibleColor}`, 10);
    }
  }, [visibleColor]);

  // Handle emoji scroll
  const handleEmojiScroll = useCallback(
    debounce((e) => {
      const anchor = document.getElementById("emoji-icons-wrapper");
      const rect = anchor?.getBoundingClientRect();
      const y = anchor?.getBoundingClientRect().y;

      // Left padding + width of the target emoji
      const x = e.target.offsetWidth / 2 + rect!.left;

      // Find all the elements at the calculated position
      // Then locate the target emoji element and pull it's id
      // TODO This is fragile. If a user scrolls, the y coordinate is no longer valid. Thus, there is no longer a relevant element at those coordinates
      if (x && y) {
        const targets = document.elementsFromPoint(x, y);
        if (targets) {
          // Note: GA scroll event captured in a useEffect
          setVisibleColor(targets[0]?.id);          
        }
      }
    }, 100),
    [],
  );

  // Helper for handling emoji click - called inline
  // TODO Test on Android - Samsung browser may not support options, just boolean
  const scrollToElement = (event) => {
    const scrollOptions = {
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    };
    event?.target?.scrollIntoView(scrollOptions);
  };

  const buttonHandlerBack = () => {
    fireGaEvent(window, "clickEvent", "results_upload-new-photo", 10);
    navigate("/");
  };

  const buttonHandlerShare = () => {
    fireGaEvent(window, "clickEvent", "results_share", 10);
    if (navigator.share) {
      const shareUrl = window.location.href;
      navigator
        .share({
          title: shareTitle, // Remove and iOS uses default. Android shows blank.
          // TODO Popo Curious Adjust as needed with Dig API done
          url: props.hasNoImage ? window.location.origin : shareUrl,
        })
        .then(() => {
          // TODO Do something?
          console.log(shareSuccessMsg);
        })
        .catch(console.error);
    } else {
      console.log("Web Share API not available");
    }
  };

  if (analysis) {
    return (
      <>
        <div className="bg-peach px-4 py-6 lg:px-[300px] lg:py-12">
          <div
            id="emoji-icons-outer-wrapper"
            className="mx-4 flex flex-col gap-y-2 overflow-hidden"
          >
            <div
              id="emoji-icons-wrapper"
              className="flex w-full snap-x snap-mandatory scroll-pl-0 overflow-scroll bg-gradient-to-r from-peach px-1/2"
              onScroll={handleEmojiScroll}
            >
              {colorRecommendations?.map(({ color }) => (
                <div
                  key={color}
                  id={color}
                  className="flex w-fit snap-center flex-col items-center py-1.5 text-body-mobile lg:font-bold"
                  // TODO Not reliable on mobile
                  // onClick={(e) => {
                  //   setVisibleColor(color);
                  //   scrollToElement(e);
                  // }}
                >
                  <div className={visibleColor === color ? "opacity-100" : "opacity-25"}>
                    <PoopIcon color={getHexColor(color)} />
                  </div>
                  <span className="mt-1 mb-1.5 min-w-[100px] text-center lg:min-w-[120px]">
                    {colorEnglishToSpanish(color)}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div id="color-recos-wrapper" className="mx-4 flex">
            {visibleColor && colorRecommendations?.map(({ color, recommendation: { label, body } }) => (
              <div
                key={color}
                id={color}
                // The desktop results bug was caused by the line below. Placing a visibleColor && guard in the JS expression seems to have fixed
                className={`flex snap-center flex-col items-center w-full text-body-mobile lg:font-bold ${
                  visibleColor === color ? "flex" : "hidden"
                }`}
              >
                <div className="left-[41%] w-9 lg:left-[15%]">
                  <div
                    className="h-6 w-6 origin-bottom-left rotate-45 transform"
                    style={{ backgroundColor: `${getHexColor(color)}` }}
                  ></div>
                </div>
                <div
                  id={`visible-content-wrapper-${color}`}
                  className="flex min-h-[280px] grow flex-col gap-y-3 border-t-[20px] bg-white p-4 shadow-mobile"
                  style={{ borderColor: `${getHexColor(color)}` }}
                >
                  <div className="text-body-desktop font-bold lg:text-body-desktop-long-text">
                    {label}
                  </div>
                  <ExpandableText
                    fontClasses="text-mobile-long-text text-[16px] font-normal leading-[24px] lg:text-[20px]"
                    targetLength={210}
                    text={body}
                    // GA click event handled w/in component
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="mt-6 flex flex-col items-center justify-center gap-y-4 lg:mt-9">
            {/* Default for invalid state */}
            {!colorPrediction && <div>{invalidStateMsg}</div>}
            {/* Submit new image */}
            <Button
              className="lg:w-fit"
              // eslint-disable-next-line @typescript-eslint/dot-notation
              variant={navigator["share"] ? "secondary" : "primary"}
              type="button"
              text={buttonSecondaryText}
              onClick={buttonHandlerBack}
            />
            {/* Share results */}
            {/* eslint-disable-next-line @typescript-eslint/dot-notation */}
            {navigator["share"] && (
              <>
                <div
                  className="text-body-mobile lg:text-body-desktop"
                >
                  {props.hasNoImage ? shareTextBodyNoImage : shareTextBody}
                </div>
                <div 
                  className="flex gap-x-2 p-3 justify-between"
                >
                  <IconWhatsapp />
                  <IconFacebook />
                  <IconShare />
                </div>
                <Button
                  className="lg:w-fit"
                  // eslint-disable-next-line @typescript-eslint/dot-notation
                  variant={navigator["share"] ? "primary" : "secondary"}
                  type="button"
                  // TODO No Image Adjust when Dig API finished
                  text={props.hasNoImage ? buttonPrimaryTextNoImage : buttonPrimaryText}
                  onClick={buttonHandlerShare}
                />
              </>
            )}
          </div>
        </div>

        <div>
          <Heading
            variant={"recommendations"}
            heading={recommendationsHeading}
            description={recommendationsDescription}
          />
          <div
            id="accordion-wrapper"
            className="flex flex-col gap-y-2 p-4 lg:gap-y-6 lg:px-[300px]"
          >
            {nonColorRecommendations?.map((key: any, index: any) => (
              <RecommendationCard
                key={`${key.id.substring(0, key.id.indexOf(" "))}-${index}`}
                id={key.id}
                label={key.label}
                body={key.body}
                clickUrl={key.clickUrl}
                imageUrl={key.imageUrl}
                productCode={key.productCode}
              />
            ))}
          </div>
        </div>
      </>
    );
  }

  return <ErrorRedirect />;
};
