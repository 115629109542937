import React from "react";

interface FormErrorProps {
  text: string;
  className?: string | undefined;
}

export const FormError = ({ text, className = "", ...props }: FormErrorProps) => (
  <p className={`mt-1 px-1 text-sm text-secondary ${className} `}>{text}</p>
);
