import React from "react";
import { useLocation } from "react-router-dom";
import parser from "html-react-parser";
import { Disclosure as Accordion } from "@headlessui/react";
import { Button } from "../Button/Button";
import { Plus } from "../Plus/Plus";
import { Minus } from "../Minus/Minus";
import { fireGaEvent } from "../../utils/helpers/analytics";
import { utm } from "../../utils/constants/es-PE/analytics";

const nestedCtaButtonText = "Conoce más";
const { outToMasAbrazos } = utm;

export interface RecommendationCardProps {
  id: string;
  label: string;
  body: string;
  clickUrl?: string;
  imageUrl?: string;
  productCode?: string;
  group?: string; // Used for content troubleshooting only
}

export const RecommendationCard = ({
  id,
  label,
  body,
  clickUrl,
  imageUrl,
  productCode,
  group,
  ...props
}: RecommendationCardProps) => {
  const location = useLocation();
  const showTroubleshooting = location.pathname.includes("all");
  return (
    <>
      <Accordion>
        {({ open }: any) => (
          // Title is only used for troubleshooting content in "all" routes
          <div title={id}>
            <Accordion.Button
              onClick={(e) => { fireGaEvent(window, "clickEvent", "results_tips-expand-accordion", 10); }}
              className="text-body-large flex w-full items-center justify-between gap-x-4 bg-peach p-4 text-left font-bold lg:p-6 lg:text-body-desktop-large"
            >
              <span>
                {label}

                {/* TODO Remove troubleshooting labeling */}
                {showTroubleshooting && (
                  <>
                    <span className="text-xs text-green-500">
                      {body.includes("href") ? " (Link)" : ""}
                      {imageUrl ? " (Image)" : ""}
                      {clickUrl ? " (Button)" : ""}
                    </span>
                    <span className="text-xs text-purple-500">
                      {group && group === "Color" ? " (Color)" : ""}
                    </span>
                  </>
                )}
              </span>
              <span className="h-4 w-4 shrink-0 text-primary lg:h-5 lg:w-5">
                {open ? <Minus /> : <Plus />}
              </span>
            </Accordion.Button>
            <Accordion.Panel className="tw-custom-accordion-styles flex flex-col items-center p-4 text-body-mobile text-[16px] leading-[24px] lg:flex-row lg:py-8 lg:px-6 lg:text-body-desktop">
              {/* TODO Need a loader guard as images are coming from Mas Abrazos site */}

              {imageUrl && (
                <div className="relative mb-2 lg:mb-4 lg:min-w-[35%]">
                  <img
                    className="relative object-contain"
                    src={imageUrl}
                    alt={label}
                    loading="eager"
                  />
                  {productCode && (
                    <div className="text-right text-xs lg:text-center">
                      <span className="rounded p-1 text-grayscale-4">{productCode}</span>
                    </div>
                  )}
                </div>
              )}
              <div className="lg:p-8" onClick={(e) => { fireGaEvent(window, "clickEvent", "results_tips-content-link", 10); }}>
                {/* TODO Capture GA event for embedded links */}
                {/* onClick={(e) => { fireGaEvent(window, "clickEvent", "results_tips-content-link", 10); }} */}
                {parser(body)}

                {clickUrl && (
                  <div className="flex justify-start pt-4">
                    <Button
                      icon="arrow"
                      iconDirection="right"
                      text={nestedCtaButtonText}
                      type="button"
                      variant="textCta"
                      href={clickUrl + outToMasAbrazos}
                      onClick={(e) => { fireGaEvent(window, "clickEvent", "results_tips-content-button-link", 10); }}
                      // Current Figma suggests larger button size, even on mobile, when an image is present
                      className={`justify-start text-left ${imageUrl ? "text-body-desktop" : ""}`}
                    />
                  </div>
                )}
              </div>
            </Accordion.Panel>
          </div>
        )}
      </Accordion>
    </>
  );
};
