import React, { useEffect, useState } from "react";
import parser from "html-react-parser";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel } from "../../FormLabel/FormLabel";
import { FormError } from "../../FormError/FormError";
import { FoodIcon } from "../../FoodIcons/FoodIcon";
import { Button } from "../../Button/Button";
import { Step1Props, Step1DataProps } from "../../../pages/Props";
import { fireGaEvent } from "../../../utils/helpers/analytics";
import { utm } from "../../../utils/constants/es-PE/analytics";

const { outToMasAbrazos } = utm;
const textFood = "*¿Qué tipo de alimento está comiendo tu bebé?";
const errorTextFood = "Por favor selecciona el tipo de comida";
const textWeight = "*¿Cuánto pesa tu bebé?";
const inputWeightPlaceholder = "Ingresa el peso de tu bebé";
const errorBabyWeight = "Ingresa un peso válido";
const textBabyBirthday = "*¿Cuándo nació el pequeño?";
const errorBabyBirthday = "Ingresa una fecha válida";
const textTerms = `*Acepto los <a class="underline" href="https://www.masabrazos.com.pe/servicio-al-cliente/terminos-y-condiciones-poopscanner.html${outToMasAbrazos}" target="_blank">Términos de uso del PopoScan</a> y <a class="underline" href="https://www.masabrazos.com.pe/servicio-al-cliente/politica-de-privacidad.html${outToMasAbrazos}" target="_blank">la Política de Privacidad</a>, y autorizo a Kimberly Clark a recopilar y usar la información que envió (incluida la información de salud) para brindar orientación sobre el bienestar de mi bebe.`;
const errorTerms = "Por favor acepta los términos para avanzar";
const buttonText = "Tengo una imagen del pañal sucio";
const buttonTextNoImage = "No tengo una imagen ahora";
const buttonHelperText = "Utiliza una imagen del pañal si la tienes";

const schema = yup
  .object({
    babyDiet: yup.string().required(errorTextFood),
    babyWeight: yup.number()
      .required(errorBabyWeight)
      .typeError(errorBabyWeight)
      .positive(errorBabyWeight),
    babyBirthday: yup.string().required(errorBabyBirthday),
    termsAndConditions1: yup
      .boolean()
      .isTrue(errorTerms)
      .required(),
  })
  .required();

const initialValues = {
  babyDiet: "",
  babyWeight: "",
  babyBirthday: "",
  termsAndConditions1: false,
}

const foods = [
  {
    label: "Lactancia",
    icon: "breastmilk",
  },
  {
    label: "Fórmula",
    icon: "formula",
  },
  {
    label: "Sólidos",
    icon: "solids",
  },
  {
    label: "Mixta",
    icon: "mixed",
  },
];

const selectedClasses =
  "group flex flex-col justify-center items-center text-center gap-2 py-2 px-[6.5px] bg-primary text-white border border-grayscale-3 rounded text-body-mobile lg:text-body-desktop lg:font-bold lg:h-[114px]";
const defaultClasses =
  "group flex flex-col justify-center items-center text-center gap-2 py-2 px-[6.5px] bg-white border rounded text-body-mobile lg:text-body-desktop lg:font-bold border-grayscale-3 hover:bg-peach hover:cursor-pointer hover:border-primary lg:h-[114px]";

export const Step1 = ({ stepsSwitch, setStepsSwitch, doesNotHaveImage, setDoesNotHaveImage, setStep1FormData }: Step1Props) => {
  const [selectedFood, setSelectedFood] = useState<string>();
  const [formData, setFormData] = useState<Step1DataProps>();

  const handleFoodClick = (icon: string) => {
    fireGaEvent(window, "clickEvent", `step-1_${icon}`, 10);
    setSelectedFood(icon);
  };

  const buttonHandlerForward = (data: Step1DataProps) => {
    setStep1FormData(data);
    setStepsSwitch("Step2");
  };

  const buttonHandlerForwardNoImage = (data: Step1DataProps) => {
    setStep1FormData(data);
    setStepsSwitch("Step2NoImage");
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit(values) {
      // TODO This feels fragile...Formik could validate before State set, no?
      if (doesNotHaveImage) {
        buttonHandlerForwardNoImage(values);
      } else {
        buttonHandlerForward(values);
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="bg-peach px-4 py-6 md:px-16 md:py-8 lg:px-[300px] lg:py-12">
        {/* Food */}
        <FormLabel text={textFood} />
        <div className="max-full mt-2 grid grid-cols-4 justify-between gap-1 self-center md:max-w-[75%] lg:gap-2">
          {/* TODO Turn these into radio inputs */}
          {foods.map(({ label, icon }) => (
            <div
              key={icon}
              onClick={async () => {
                handleFoodClick(icon);
                formik.handleBlur(icon);
                formik.handleChange(icon);
                await formik.setFieldValue("babyDiet", icon);
              }}
              className={`${selectedFood === icon ? selectedClasses : defaultClasses}`}
            >
              <div className="lg:h-12 lg:w-12">
                <FoodIcon isSelected={selectedFood === icon} icon={icon} />
              </div>
              {label}
            </div>
          ))}
        </div>
        {formik.touched.babyDiet && formik.errors.babyDiet ? (
          <FormError text={formik.errors.babyDiet} />
        ) : null}
        {/* Weight */}
        {/* Number */}
        <FormLabel htmlFor="babyWeight" text={textWeight} className="pt-6 lg:pt-10" />
        <div className="mt-2 flex w-full flex-row items-center justify-between gap-4 lg:gap-8">
          <input
            type="number"
            name="babyWeight"
            id="babyWeight"
            step="0.01" // Allow decimals
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onClick={(e) => { fireGaEvent(window, "formChange", `step-1_${e.currentTarget.id}`, 15); }}
            placeholder={inputWeightPlaceholder}
            className="max-full block w-full grow rounded border border-grayscale-3 py-2.5 pt-[9px] pb-2 text-body-mobile-input outline-0 placeholder:text-grayscale-4 focus:border-secondary focus:ring-secondary lg:text-body-desktop"
          />

          {/* Kilos or Pounds */}
          <fieldset className="flex-none">
            <div className="flex">
              <div className="mr-2 flex items-center">
                <input
                  id="kg"
                  type="radio"
                  value="kg"
                  name="weight-unit-radio-group"
                  defaultChecked
                  className="h-4 w-4 border-gray-300 bg-gray-100 text-primary focus:!ring-0 focus:ring-offset-0"
                />
                <label
                  htmlFor="kg"
                  className="ml-2 text-body-mobile-large lg:text-body-desktop"
                >
                  kg
                </label>
              </div>
              <div className="mr-2 flex items-center">
                <input
                  id="lb"
                  type="radio"
                  value="lb"
                  name="weight-unit-radio-group"
                  className="h-4 w-4 border-gray-300 bg-gray-100 text-primary focus:!ring-0 focus:ring-offset-0"
                />
                <label
                  htmlFor="lb"
                  className="ml-2 text-body-mobile-large lg:text-body-desktop"
                >
                  lb
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        {formik.touched.babyWeight && formik.errors.babyWeight ? (
          <FormError text={formik.errors.babyWeight} />
        ) : null}

        {/* Datepicker */}
        <FormLabel text={textBabyBirthday} htmlFor="babyBirthday" className="pt-6 lg:pt-10" />
        <div className="mt-2 block w-full">
          <input
            type="date"
            id="babyBirthday"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onClick={(e) => { fireGaEvent(window, "formChange", `step-1_${e.currentTarget.id}`, 15); }}
            className="max-full block w-full rounded border border-grayscale-3 py-2.5 pt-[9px] pb-2 text-body-mobile-input text-grayscale-4 outline-0 focus:border-secondary focus:text-black focus:ring-secondary lg:text-body-desktop"
          />
          {formik.touched.babyBirthday && formik.errors.babyBirthday ? (
            <FormError text={formik.errors.babyBirthday} />
          ) : null}
        </div>

        {/* Disclaimer */}
        <div className="py-8 lg:py-14">
          <div className="flex items-start">
            <input
              id="termsAndConditions1"
              type="checkbox"
              value=""
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={(e) => { fireGaEvent(window, "clickEvent", `step-1_${e.currentTarget.id}`, 15); }}
              className="h-4 w-4 border-primary text-primary bg-transparent focus:!ring-0 focus:ring-offset-0"
            />
            <label
              htmlFor="termsAndConditions1"
              className="ml-2 text-body-mobile-small lg:text-body-desktop-small"
            >
              {parser(textTerms)}
            </label>
          </div>
          {formik.touched.termsAndConditions1 && formik.errors.termsAndConditions1 ? (
            <FormError className={"pl-6"} text={formik.errors.termsAndConditions1} />
          ) : null}
        </div>

        <div className="w-full text-center">
          <Button
            variant="primary"
            type="submit"
            text={buttonText}
            onClick={(e) => { 
              fireGaEvent(window, "clickEvent", "step-1_submit_has_image", 15); // TODO Confirm w/ Carlos
            }}
          />
        </div>
        <div className="w-full text-center">
          <Button
            variant="secondary"
            className="mt-3 lg:mt-4"
            type="submit"
            text={buttonTextNoImage}
            name="no-image"
            onClick={(e) => {

              fireGaEvent(window, "clickEvent", "step-1_submit_no_image", 15); // TODO Confirm w/ Carlos
              setDoesNotHaveImage(true);
            }}
          />
        </div>
        <div className="text-center text-body-mobile-small lg:text-body-desktop-small mt-3 lg:mt-4">{buttonHelperText}</div>
      </div>
    </form>
  );
};
