/**
 * The only buttons created were those required by the current Poopscanner Figma file (v2 of UX)
 * TODO Add all other icon options e.g. chevrons, right arrow, etc
 * TODO Add all other iconDirection options e.g. right, up, down
 */
import React from "react";
import { LeftArrow } from "../Arrows/LeftArrow";
import { RightArrow } from "../Arrows/RightArrow";
import { LeftChevron } from "../Arrows/LeftChevron";
import { RightChevron } from "../Arrows/RightChevron";

interface ButtonProps {
  children?: JSX.Element;
  variant: "primary" | "secondary" | "disabled" | "text" | "textCta";
  type: "button" | "submit" | "reset";
  text: string;
  icon?: false | "arrow" | "chevron";
  iconDirection?: "left" | "right";
  className?: string | undefined;
  href?: string;
  name?: "no-image" | undefined;
  onClick?: (e) => void;
}

// Tailwind ClassNames
/* eslint-disable no-multi-spaces */
const primaryButton =
  "w-full lg:w-auto text-white bg-primary hover:bg-secondary border-2 border-primary hover:border-secondary focus:border-secondary focus:bg-secondary focus:outline-none text-center inline-flex items-center justify-center gap-1 font-medium rounded-md text-body-mobile-large lg:text-body-desktop px-[24px] pt-[9px] pb-[8px] lg:px-[32px] lg:py-[9px]";
const primaryDisabledButton =
  "w-full lg:w-auto text-white bg-grayscale-4 border-2 border-grayscale-4 focus:outline-none text-center inline-flex items-center justify-center gap-1 font-medium rounded-md text-body-mobile-large lg:text-body-desktop px-[24px] pt-[9px] pb-[8px] lg:px-[32px] lg:py-[9px]";
const secondaryButton =
  "w-full lg:w-auto text-primary hover:text-secondary border-2 border-primary hover:border-secondary focus:border-secondary focus:outline-none box-border text-center inline-flex items-center justify-center gap-1 font-medium rounded-md text-body-mobile-large lg:text-body-desktop px-[24px] pt-[9px] pb-[8px] lg:px-[32px] lg:py-[9px]";
const textButton =
  "w-full lg:w-auto text-primary hover:text-secondary border-2 border-transparent focus:outline-none box-border text-center inline-flex items-center justify-center gap-1 font-medium rounded-md text-body-desktop-small lg:text-body-desktop px-[24px] pt-[9px] pb-[8px] lg:px-[32px] lg:py-[9px]";
const textCtaButton =
  "lg:w-auto text-primary hover:text-secondary border-2 border-transparent focus:outline-none box-border text-center inline-flex items-center gap-1 font-medium rounded-md text-body-desktop-small lg:text-body-desktop pt-[9px] pb-[8px] lg:py-[9px]";
/* eslint-enable no-multi-spaces */

export const Button = ({
  variant,
  type,
  href,
  text,
  icon,
  iconDirection,
  className = "",
  onClick,
  children,
  ...props
}: ButtonProps) => {
  /* eslint-disable @typescript-eslint/indent */
  let variantClass =
    variant === "primary"
      ? primaryButton
      : variant === "secondary"
      ? secondaryButton
      : variant === "disabled"
      ? primaryDisabledButton
      : variant === "text"
      ? textButton
      : variant === "textCta"
      ? textCtaButton
      : "primary";
  /* eslint-enable @typescript-eslint/indent */
  // Append any additional classes passed in via props
  variantClass = `${variantClass} ${className}`;

  return (
    <button className={variantClass} type={type} onClick={onClick} {...props}>
      <>
        {icon && icon === "arrow" && iconDirection === "left" && <LeftArrow />}
        {icon && icon === "chevron" && iconDirection === "left" && <LeftChevron />}
        {href ? <a href={href}>{text}</a> : text}
        {children}
        {icon && icon === "arrow" && iconDirection === "right" && <RightArrow />}
        {icon && icon === "chevron" && iconDirection === "right" && <RightArrow />}
      </>
    </button>
  );
};
