import React from "react";
import parser from "html-react-parser";
import Divider from "../Divider/Divider";
import MaLogo from "../../assets/images/ma-powered-by.png"

interface FooterProps {
  children?: JSX.Element;
  text: string;
}

export const Footer = ({text, ...props}: FooterProps) => (
  <>
    <div className="p-4 text-center text-body-mobile-small lg:text-body-desktop-small lg:px-[300px] lg:py-10">
      {parser(text)}
    </div>
    <Divider />
    <div className="px-4 text-body-mobile lg:text-body-desktop lg:px-[300px] lg:py-10">
      <div className="font-serif font-bold text-heading-4-mobile lg:text-body-desktop pt-11">
        Sobre Más Abrazos
      </div>
      <div>
        <ul>
          <li className="pt-5">
            <a className="text-primary" href="https://www.kimberly-clark.com/es-es/global-privacy-policy" title="Política de privacidad" target="_blank" rel="noreferrer">
              Política de privacidad
            </a>
          </li>
          <li className="pt-5">
            <a className="text-primary" href="https://www.kimberly-clark.com/es-es/privacy-values" title="Valores sobre privacidad" target="_blank" rel="noreferrer">
              Valores sobre privacidad
            </a>
          </li>
          <li className="pt-5">
            <a className="text-primary" href="https://www.masabrazos.com.pe/servicio-al-cliente/terminos-y-condiciones.html" title="Términos y condiciones" target="_blank" rel="noreferrer">
              Términos y condiciones
            </a>
          </li>
          <li className="pt-5">
            <a className="text-primary" href="https://www.masabrazos.com.pe/servicio-al-cliente/terminos-y-condiciones-poopscanner.html" title="Términos y condiciones Popó Scan" target="_blank" rel="noreferrer">
              Términos y condiciones Popó Scan
            </a>
          </li>
          <li className="pt-5">
            {/* One Trust footer style overrides are in App.css */}
            <a 
              id="ot-sdk-btn" 
              title="Configuración de Cookies"
              className="!text-primary cursor-pointer one-trust-reset-styles ot-sdk-show-settings"
              data-gtm="{'event':'Event','eventTypes':'click','action':'click','label':'Configuración de Cookies','value':'10', 'category':'footer'}"
            >
              Configuración de cookies
            </a>
          </li>
        </ul>
      </div>
      <div className="font-serif font-bold text-heading-4-mobile lg:text-body-desktop pt-11">
        Contactos
      </div>
      <div>
        <ul>
          <li className="pt-5">
          Teléfono: 0-800-004-66
          </li>
        </ul>
      </div>
    </div>
    <div className="flex justify-center my-11">
      <img
        src={MaLogo}
        // Optimization test: set width/height to desktop max. Helps with CLS and improves vanity metrics in Lighthouse.
        style={{ width: "155px", height: "76px" }}
        className=""
        alt="Más Abrazos by Huggies Logo"
      />
    </div>
    <div className="text-center px-4 py-5 text-body-mobile-small bg-grayscale-2 lg:text-body-desktop-small">
    2023 © Kimberly–Clark Worldwide. <span className="lg:hidden"><br /></span>Todos los derechos reservados.
    </div>
  </>
);
