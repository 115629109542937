import React from "react";
import parser from "html-react-parser";
import { Progress } from "../ProgressIndicator/Progress";

interface FormHeadingProps {
  children?: JSX.Element;
  // TODO extend step and label from ProgressProps
  variant?: "steps" | "recommendations";
  step?: 1 | 2 | 3 | "completed";
  label?: string;
  subheading?: string;
  heading: string;
  description: string;
}

// Tailwind Class Names
/* eslint-disable no-multi-spaces */
const stepHeadingClasses =            "flex flex-col mt-4 font-serif font-bold lg:mt-10 text-heading-3-mobile lg:text-heading-4-desktop";
const recommendationsHeadingClasses = "flex flex-col mt-4 font-serif font-bold lg:mt-10 text-heading-3-mobile lg:text-heading-3-desktop";

const stepDescriptionClasses =            "mt-1 lg:mt-4 text-body-mobile-long-text lg:text-body-desktop";
const recommendationsDescriptionClasses = "mt-1 lg:mt-4 text-body-mobile-long-text lg:text-body-desktop";
/* eslint-enable no-multi-spaces */

export const Heading = ({variant, step, label, subheading, heading, description, ...props}: FormHeadingProps) => (
  <div className="px-4 py-4 text-center lg:px-[300px] lg:py-6">
    {step && label && <Progress step={step} label={label} />}
    <h1 className={variant === "steps" ? stepHeadingClasses : recommendationsHeadingClasses}>
      <span className="mb-1 font-sans font-bold lg:mb-4 text-body-mobile-large lg:text-body-desktop">{subheading}</span>
      {heading}
    </h1>
    <p className={variant === "steps" ? stepDescriptionClasses : recommendationsDescriptionClasses}>{parser(description)}</p>
  </div>
);
