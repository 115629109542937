import React from "react";
import { Checkmark } from "../Checkmark/Checkmark";

interface ProgressProps {
  step: 1 | 2 | 3 | "completed";
  label: string;
}

// Tailwind ClassNames
/* eslint-disable no-multi-spaces */
const activeStep =   "text-primary text-step-active box-border px-6 py-2.5 border-2 rounded-full border-primary";
const pastStep = "text-primary box-border flex items-center justify-center w-[45px] h-[45px]";
const futureStep = "text-grayscale-4 box-border flex items-center justify-center rounded-full w-[45px] h-[45px] bg-peach";
/* eslint-enable no-multi-spaces */

export const Spacer = () => (
  <hr className="w-6 border-t-2 border-primary" />
);

export const Progress = ({step, label, ...props}: ProgressProps) => {
  const test = "";
  // TODO DRY this up once finalized
  return (
    <div className="flex items-center justify-center font-body-desktop">
      <div className="relative flex items-center justify-center">
        <div className={step === 1 ? activeStep : pastStep }>
          {step === 1 ? `${label} 1` : <Checkmark /> }
        </div>
        <Spacer />
        <div className={step === 2 ? activeStep : step < 2 ? futureStep : pastStep }>
          {step === 2 ? `${label} 2` : step < 2 ? 2 : <Checkmark /> }
        </div>
        <Spacer />
        <div className={step === 3 ? activeStep : step < 3 ? futureStep : pastStep }>
          {step === 3 ? `${label} 3` : step < 3 ? 3 : <Checkmark /> }
        </div>
      </div>
    </div>
  );
};
