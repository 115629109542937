import React from "react";
import "./styles.css";
import huggiesImage from "../../assets/images/huggies-monogram.png";

export interface LoadingIndicatorProps {
  loadingAssetSrc: string;
  loadingText: string;
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  loadingAssetSrc,
  loadingText,
}) => (
  <div className="loading">
    <img
      src={loadingAssetSrc || huggiesImage}
      alt="logo"
      style={{ maxHeight: 150, maxWidth: 150 }}
    />
    <h5 className="loading-text font-serif">{loadingText}</h5>
  </div>
);
