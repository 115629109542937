import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/Home/Home";
import { Results } from "./pages/Results/Results";
import { ResultsAlt } from "./pages/Results/ResultsAlt";
import { AllResults } from "./pages/Results/AllResults";
import { NotFound } from "./components/NotFound/NotFound";
import { AnalysisReturnProps } from "./pages/Props";

const App = () => {
  const [analysis, setAnalysis] = useState<AnalysisReturnProps>();

  return (
    <Routes key={location.pathname} location={location}>
      <Route path="/" element={<Home analysis={analysis} setAnalysis={setAnalysis} />} />
      <Route
        path="/analysis/:imageId"
        element={<Results analysis={analysis} setAnalysis={setAnalysis} />}
      />
      {/* TODO Finalize No Image */}
      <Route
        path="/resultados-rapidos/:color"
        element={<ResultsAlt analysis={analysis} setAnalysis={setAnalysis} />}
      />
      <Route
        path="/all/:regionCode"
        element={<AllResults analysis={analysis} setAnalysis={setAnalysis} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
