/**
 * Takes a string of form rgb(0,0,0)
 * Returns an object of form {red: 0, green: 0, blue: 0}
 */
export const rgbToObj = (rgb) => {
  const colors = ["red", "green", "blue", "alpha"];
  const colorArr = rgb.slice(
    rgb.indexOf("(") + 1,
    rgb.indexOf(")"),
  ).split(",");

  // eslint-disable-next-line no-new-object
  const obj = new Object();
  colorArr.forEach((k, i) => {
    obj[colors[i]] = parseInt(k, 10);
  });

  return obj;
};
