import { AnalysisSubmitProps } from "./Props";

/* eslint-disable @typescript-eslint/naming-convention  */
const BASE_URL = process.env.REACT_APP_BASE_URL;
const DIG_TOKEN = process.env.REACT_APP_DIG_TOKEN;
const REGION_CODE = process.env.REACT_APP_REGION_CODE;
const CONNECTION_ID = process.env.REACT_APP_CONNECTION_ID;
const REGION_CODE_AUS = process.env.REACT_APP_REGION_CODE_AUS;
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * Initial calls to DigLabs API
 * 1) Request a signed URL
 * 2) Upload the image
 * This is Dig"s Get Image Upload Location endpoint
 */
export const getImageUploadLocation = async (file: File) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const digEndpoint = `${BASE_URL}/api/analysis/getSignedUrl`;

  // TODO This is blocking, but can run asynchronously with the signedUrl fetch (e.g. use Promise.all)
  const buf = await file.arrayBuffer();

  try {
    // Get signed URL from DigLabs
    const fetchSignedUrl = await fetch(digEndpoint, {
      method: "POST",
      body: JSON.stringify({
        mimeType: file?.type,
        connectionId: CONNECTION_ID,
      }),
      headers: <HeadersInit>{
        "content-type": "application/json",
        /* eslint-disable @typescript-eslint/naming-convention */
        dig_token: DIG_TOKEN,
        region_code: REGION_CODE,
        /* eslint-enable @typescript-eslint/naming-convention */
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { url, imageId } = await fetchSignedUrl.json();

    // Upload the image
    const uploadImage = await fetch(url, {
      method: "PUT",
      body: buf,
      headers: { "content-type": file.type },
    });
    const result = await uploadImage.text();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return { imageId, result };
  } catch (err: unknown) {
    return {
      error: err,
      message: "Error uploading image",
    };
  }
};

/**
 * Submit the payload for Analysis
 * This is Dig"s Run Analysis endpoint
 */
export const submitForDigAnalysis = async (imageId: string, payload: AnalysisSubmitProps) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const digEndpoint = `${BASE_URL}/api/analysis`;
  try {
    const runAnalysis = await fetch(digEndpoint, {
      method: "POST",
      body: JSON.stringify({
        imageId,
        connectionId: CONNECTION_ID,
        meta: payload,
      }),
      headers: <HeadersInit>{
        "content-type": "application/json",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dig_token: DIG_TOKEN,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        region_code: REGION_CODE,
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const analysisResults = await runAnalysis.json();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return analysisResults;
  } catch (err: unknown) {
    return {
      error: err,
      message: "Error submitting payload for analysis",
    };
  }
};

/**
 * Fetch an analysis based on imageId
 * This is Dig"s Retrieve Analysis for Image endpoint
 */
export const fetchDigAnalysis = async (imageId: string | undefined) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const digEndpointFetchAnalysis = `${BASE_URL}/api/analysis?imageId=${imageId}`;
  try {
    const getAnalysis = await fetch(digEndpointFetchAnalysis, {
      headers: <HeadersInit>{
        "content-type": "application/json",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dig_token: DIG_TOKEN,
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await getAnalysis.json();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response;
  } catch (err: unknown) {
    return {
      error: err,
      message: "Error fetching analysis",
    };
  }
};

/**
 * Retrieve all recommendations (color and non-color)
 * Used to troubleshoot and guide content discussions
 */
export const fetchAllRecommendations = async (regionCode: string) => {
  const digEndpointFetchAll = `${BASE_URL}/api/content/all`;
  try {
    const getAnalysis = await fetch(digEndpointFetchAll, {
      headers: <HeadersInit>{
        "content-type": "application/json",
        /* eslint-disable @typescript-eslint/naming-convention */
        dig_token: DIG_TOKEN,
        region_code: regionCode || "PE",
        /* eslint-enable @typescript-eslint/naming-convention */
      },
    });
    const response = await getAnalysis.json();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response;
  } catch (err: unknown) {
    return {
      error: err,
      message: "Error fetching analysis",
    };
  }
};

export default getImageUploadLocation;
