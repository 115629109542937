import React from "react";

export const Divider = () => {
  const dividerStyle = {};
  return (
    <div className="huggies-divider" />
  );
};

export default Divider;
