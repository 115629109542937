import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Hero } from "../../components/Hero/Hero";
import { Divider } from "../../components/Divider/Divider";
import { Heading } from "../../components/Headings/Heading";
import { loading, hero } from "../../utils/constants/es-PE/all";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { RecommendationCard } from "../../components/RecommendationCard/RecommendationCard";
import { fetchAllRecommendations } from "../../pages/functions";
import { Footer } from "../../components/Footer/Footer";
import { ErrorRedirect } from "../../components/Error";
import { AnalysisStateProps } from "../Props";

const { heading, description } = hero;
const recommendationsHeading = "¿Quieres saber más?";
const recommendationsDescription =
  "¡Obtén información con nuestra lista personalizada de artículos, recursos y productos!";

export const AllAccordions = (obj: any) => {
  if (obj) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.data.map((key: any, index: any) => (
      <RecommendationCard
        key={`${key.id.substring(0, key.id.indexOf(" "))}-${index}`}
        id={key.id}
        label={key.label}
        body={key.body}
        clickUrl={key.clickUrl}
        imageUrl={key.imageUrl}
        productCode={key.productCode}
        group={key.group}
      />
    ));
  }
};

export const AllResults = ({ analysis, setAnalysis }: AnalysisStateProps) => {
  const [allRecommendations, setAllRecommendations] = useState();
  const [filteredRecommendations, setFilteredRecommendations] = useState();
  const { regionCode } = useParams();

  useEffect(() => {
    if (!allRecommendations) {
      const fetchData = async () => {
        const data = await fetchAllRecommendations(regionCode!);
        if (data) {
          const targetContent = data.recommendations.filter((tip) => tip.group !== "Color");
          const filtered = data.recommendations.filter((tip) => tip.group === "Color");
          console.log(`Number of Non-Color Recommendations: ${targetContent.length}`);
          console.log(`Number of Color Recommendations: ${filtered.length}`);
          setAllRecommendations(targetContent);
          setFilteredRecommendations(filtered);
        }
      };

      void fetchData();
    }
  }, [allRecommendations]);

  // Loading - waiting for Dig response
  if (!allRecommendations) {
    return <LoadingIndicator loadingAssetSrc="" loadingText={loading.generic} />;
  }

  // Data returned
  if (allRecommendations) {
    return (
      <>
        <Hero heading={heading} description={description} />
        <Divider />
        <div>
          <Heading
            variant={"recommendations"}
            heading={recommendationsHeading}
            description={recommendationsDescription}
          />
          {regionCode === "PE" && (
            <div className="bg-grayscale-2 p-4 lg:px-[300px]">
              <div className="font-md font-bold">LAO Team...Hola 👋</div>
              <p className="my-4">Below are all 195 tips provided in the Excel spreadsheet.</p>
              <p className="my-4">
                <span className="text-lg font-bold">Task:</span>
                <br />
                Please review the existing tips below.
                <br />
                Note how very few contain images or "Learn More" links. They are mostly text. Only
                ~15% contain links.
              </p>

              <p className="my-4">
                <span className="text-lg font-bold">Opportunity:</span>
                <br />
                Provide richer content to improve engagement (e.g. back to Mas Abrazos)
              </p>

              <p className="my-4">
                <span className="text-lg font-bold">Discussion:</span>
                <br /> Australia has a number of cards with rich content including images and "Learn
                More" links (Column H on the Tips page in the Excel file). The first card below is
                one such example. The PopoScan results do not yet contain any of these richer cards.
              </p>
              <p className="my-4">
                <span className="text-lg font-bold">How to use this webpage:</span>
                <br /> Each header has <span className="text-green-500">green tags</span> to help
                you quickly review which cards have content OTHER THAN simple text. Note how none
                have images nor buttons - they are text only.
              </p>
              <p className="my-4">
                <span className="text-lg font-bold">Developer Tips & Troubleshooting:</span>
                <br /> Color and non-color recommendations are mixed. Color recommendations have a{" "}
                <span className="text-purple-500">purple tag</span> in the accordion header.
                <br /> If needed, the id of each tip is included as a title attribute in the
                accordion header (i.e. hover to view or view source).
              </p>
            </div>
          )}
          <div
            id="accordion-wrapper"
            className="flex flex-col gap-y-2 p-4 lg:gap-y-6 lg:px-[300px]"
          >
            <div className="font-xs text-green-500">Here are ALL PopoScan recommendations...</div>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/prefer-optional-chain */}
            {allRecommendations && <AllAccordions data={allRecommendations} />}
            <div className="font-xs text-green-500">
              The following tips will not have links or images, and that is OK (they represent the
              initial text visitors see near the color poop emoji slider)
            </div>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/prefer-optional-chain */}
            {filteredRecommendations && <AllAccordions data={filteredRecommendations} />}
          </div>
        </div>
        <Footer text="NOTA: Estos consejos están destinados a brindar una guía general basada únicamente en el color de las heces de tu bebé y no deben considerarse como un consejo médico. Si tu bebé presenta otros síntomas o no estás seguro de algo, habla con tu profesional de la salud lo antes posible." />
      </>
    );
  }

  // Error - invalid state
  // If no matching image, Dig returns a 200 with message:  {"message":"We could not find an analysis corresponding to this image id."}
  return <ErrorRedirect />;
};
