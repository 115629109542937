import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Hero } from "../../components/Hero/Hero";
import { Divider } from "../../components/Divider/Divider";
import { loading } from "../../utils/constants/es-PE/all";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { Heading } from "../../components/Headings/Heading";
import { Recommendations } from "../../components/Recommendations/Recommendations";
import { Footer } from "../../components/Footer/Footer";
import { ErrorRedirect } from "../../components/Error";
import { fetchDigAnalysis } from "../functions";
import { hero } from "../../utils/constants/es-PE/all";
import { utm } from "../../utils/constants/es-PE/analytics";
import { fireGaEvent } from "../../utils/helpers/analytics";
import { colorEnglishToSpanish } from "../../utils/helpers/translate";
import { Button } from "../../components/Button/Button";
import { AnalysisStateNoImageProps } from "../Props";
import { getHexColor } from "../../utils/helpers/getHexColor";

const { heading, description } = hero;
const { outToMasAbrazos } = utm;
const sourceVerified = "Resultados Detallados";
const sourceRapid = "Resultados Rápidos";
const noImageHeader = "¡Aquí están los resultados!";
const bottomCtaText = "Ver más en Más Abrazos";
const bottomCtaLink = "https://www.masabrazos.com.pe/mi-bebe/";

export const ResultsAlt = ({ analysis, setAnalysis}: AnalysisStateNoImageProps) => {
  const [serverError, setServerError] = useState(false);
  // Pull imageId from params
  const params = useParams();
  const { imageId } = params;

  const fetchAnalysisIfNeeded = async () => {
    const data = await fetchDigAnalysis(imageId);

    // Dig returns a data object with a 500 error if no results for provided imageId
    if (data?.statusCode && data.statusCode !== 200) {
      setServerError(true);
    }
    
    // If an imageId is present, there is data available to set in state
    // No Image Scenario (contingency) - this will never be reached as fetchAnalysisIsNeeded will not be called
    if (data?.imageId) {
      setAnalysis(data)
    }
  };

  // No Image - contingency flow causes a re-rerender, and thus a 500 when it tries to fetch an unknown imageId 
  // useEffect(() => {
  //   if (!analysis?.imageId) {
  //     void fetchAnalysisIfNeeded();
  //   }
  // }, [analysis]);

  // Handle No Image, page refresh
  // Show special No Image loader text, then send to homepage
  if (!analysis) {
    return <ErrorRedirect noImage={true} />
  }

  // Initial Loader
  if (!analysis && !serverError) {
    console.log("There is no analysis, and no Server Error");
    // No Image...might have to update after Dig API changes finished
    return <LoadingIndicator loadingAssetSrc="" loadingText={loading.analyzing} />;
  }

  // Handle server side errors
  if (serverError) {
    return <ErrorRedirect />
  }

  // Hydrate page with analysis results
  if (analysis) {
    // Note in No Image scenario, there is no colorPrediction in the Dig API response
    // Until Dig API updates are finalized, colorPrediction is manually set in Step3Confirm for No Image scneario
    const {
      source,
      colorPrediction,
      content: { headline },
    } = analysis;

    // Set No Image contingency helper
    // TODO Adjust handling when Dig finalizes API
    const hasNoImage = headline === "Here are your results!";

    return (
      <>
        <Hero heading={heading} description={description} />
        <Divider />

        {/* Sub-heading w results summary */}
        {colorPrediction && (
          <Heading
            subheading={source && source === "HUMAN_IN_THE_LOOP" ? sourceVerified : sourceRapid}
            // TODO Remove this temp placeholder when Dig API changes are finalized
            heading={hasNoImage ? noImageHeader : headline}
            description={`Conoce el popó <span style='color:${getHexColor(colorPrediction)} '>${colorEnglishToSpanish(
              colorPrediction,
            ).toUpperCase()}</span> de tu bebé`}
            step="completed"
            label="Paso"
          />
        )}

        {/* Recommendations */}
        <Recommendations analysis={analysis} setAnalysis={setAnalysis} hasNoImage={hasNoImage} />

        {/* CTA Back to Mas Abrazos */}
        <div className="flex justify-center p-4 lg:p-6">
          <Button
            variant="text"
            type="button"
            icon="arrow"
            iconDirection="right"
            text={bottomCtaText}
            href={bottomCtaLink + outToMasAbrazos}
            onClick={(e) => { fireGaEvent(window, "clickEvent", "results_ver-mas-en-mas-abrazos", 10); }}
          />
        </div>

        <Footer text="NOTA: Estos consejos están destinados a brindar una guía general basada únicamente en el color de las heces de tu bebé y no deben considerarse como un consejo médico. Si tu bebé presenta otros síntomas o no estás seguro de algo, habla con tu profesional de la salud lo antes posible." />
      </>
    );
  }

  // Error - invalid state
  return <ErrorRedirect />;
};
