import React, { useState, useEffect } from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import { Heading } from "../Headings/Heading";
import { Step1 } from "./Step1/Step1";
import { Step2 } from "./Step2/Step2";
import { Step2NoImage } from "./Step2/Step2NoImage"
import { Step3 } from "./Step3/Step3";
import { Step3Confirm } from "./Step3/Step3Confirm";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { Footer } from "../Footer/Footer";
import { headers, footers, loading } from "../../utils/constants/es-PE/all";
import {
  AnalysisStateProps,
  StepsSwitchProps,
  Step1DataProps,
  Step2NoImageProps,
  Step3DataProps,
  Step3ConfirmDataProps,
  FileProps,
} from "../../pages/Props";

export const Steps = ({ analysis, setAnalysis }: AnalysisStateProps) => {
  // Handle switching between steps
  const [stepsSwitch, setStepsSwitch] = useState<StepsSwitchProps>("Step1");

  // Capture UTM parameters
  // Note this could be simplified by using searchParameters instead of useLocation().search
  const [searchParams, setSearchParams] = useSearchParams();
  const [utm, setUtm] = useState<string | undefined>(undefined);
  const utmParams = useLocation().search;

  useEffect(() => {
    if (utmParams && !utm) {
      setUtm(utmParams);
      // setSearchParams("");
    }
  }, [utmParams]);

  // Set flag for No Image flow
  const [doesNotHaveImage, setDoesNotHaveImage] = useState<boolean | undefined>();

  // Capture form data at each step
  const [step1FormData, setStep1FormData] = useState<Step1DataProps | undefined>();
  const [selectedImage, setSelectedImage] = useState<FileProps | undefined>();
  const [imageAwsId, setImageAwsId] = useState<string | undefined>();
  const [step3FormData, setStep3FormData] = useState<Step3DataProps | undefined>();
  const [step3ConfirmFormData, setStep3ConfirmFormData] = useState<Step3ConfirmDataProps | undefined>();

  switch (stepsSwitch) {
    case "Step1":
      return (
        <>
          <Heading
            heading={headers.step1.heading}
            description={headers.step1.description}
            step={1}
            label={headers.label}
          />
          <Step1
            stepsSwitch={stepsSwitch}
            setStepsSwitch={setStepsSwitch}
            doesNotHaveImage={doesNotHaveImage}
            setDoesNotHaveImage={setDoesNotHaveImage}
            setStep1FormData={setStep1FormData}
          />
          <Footer text={footers.step1.footer} />
        </>
      );
    case "Step2":
      return (
        <>
          <Heading
            heading={headers.step2.heading}
            description={headers.step2.description}
            step={2}
            label={headers.label}
          />
          <Step2
            stepsSwitch={stepsSwitch}
            setStepsSwitch={setStepsSwitch}
            setSelectedImage={setSelectedImage}
            setImageAwsId={setImageAwsId}
          />
          <Footer text={footers.step2.footer} />
        </>
      );
    case "Step2NoImage":
      return (
        <>
          <Heading
            heading={headers.step2NoImage.heading}
            description={headers.step2NoImage.description}
            step={2}
            label={headers.label}
          />
          <Step2NoImage
            stepsSwitch={stepsSwitch}
            setStepsSwitch={setStepsSwitch}
            doesNotHaveImage={doesNotHaveImage}
            setDoesNotHaveImage={setDoesNotHaveImage}
            setStep3FormData={setStep3FormData}
          />
          <Footer text={footers.step2.footer} />
        </>
      );
    case "Step3":
      return (
        <>
          <Heading
            heading={headers.step3.heading}
            description={headers.step3.description}
            step={3}
            label={headers.label}
          />
          <Step3
            stepsSwitch={stepsSwitch}
            setStepsSwitch={setStepsSwitch}
            selectedImage={selectedImage}
            setStep3FormData={setStep3FormData}
          />
          <Footer text={footers.step3.footer} />
        </>
      );
    case "Step3Confirm":
      return (
        <>
          <Heading
            heading={headers.step3Confirm.heading}
            description={headers.step3Confirm.description}
            step={3}
            label={headers.label}
          />
          <Step3Confirm
            utm={utm}
            stepsSwitch={stepsSwitch}
            setStepsSwitch={setStepsSwitch}
            step1FormData={step1FormData}
            selectedImage={selectedImage}
            imageAwsId={imageAwsId}
            step3FormData={step3FormData}
            setAnalysis={setAnalysis}
            step3ConfirmFormData={step3ConfirmFormData}
            setStep3ConfirmFormData={setStep3ConfirmFormData}
            // No Image
            doesNotHaveImage={doesNotHaveImage}
            setDoesNotHaveImage={setDoesNotHaveImage}
          />
          <Footer text={footers.step3Confirm.footer} />
        </>
      );
    case "Loading":
      // This could be improved by changing the loading message (e.g. Analyzing vs Analyzing your image)
      // Keeping it simple for now by dropping "tu imagen" in both scenarios
      return <LoadingIndicator loadingAssetSrc="" loadingText={loading.analyzingNoImage} />;
    default:
      return <div>Not Found</div>;
  }
};
