/* eslint-disable @typescript-eslint/naming-convention */
export interface ColorMapProps {
  hex: string;
  id: string;
  id_spanish: string;
  spanish: string;
}

export const colorMap: ColorMapProps[] = [
  {
    hex: "#936423",
    id: "brown",
    id_spanish: "cafe",
    spanish: "Café",
  },
  {
    hex: "#185D38",
    id: "dark_green",
    id_spanish: "verde_oscuro",
    spanish: "Verde Oscuro",
  },
  {
    hex: "#4DAB75",
    id: "light_green",
    id_spanish: "verde_claro",
    spanish: "Verde Claro",
  },
  {
    hex: "#D97E00",
    id: "orange",
    id_spanish: "naranja",
    spanish: "Naranja",
  },
  {
    hex: "#EDD880",
    id: "yellow",
    id_spanish: "amarillo",
    spanish: "Amarillo",
  },
  {
    hex: "#666666",
    id: "gray",
    id_spanish: "gris",
    spanish: "Gris",
  },
  {
    hex: "#DA291C",
    id: "red",
    id_spanish: "rojo",
    spanish: "Rojo",
  },
  {
    hex: "#000000",
    id: "black",
    id_spanish: "negro",
    spanish: "Negro",
  },
  {
    hex: "#CCCCCC",
    id: "white",
    id_spanish: "blanco",
    spanish: "Blanco",
  },
];
