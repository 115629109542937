import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import iconUpload from "../../../assets/images/cloud-upload.svg";
import { Button } from "../../Button/Button";
import { Step2Props } from "../../../pages/Props";
import { fireGaEvent } from "../../../utils/helpers/analytics";
import getImageUploadLocation from "../../../pages/functions";

const textSubFooter = "*Apto para bebés hasta los 24 meses.";
const buttonTextInnerTop = "Toma una Foto";
const buttonTextInnerTopDesktop = "Arrastra y Suelta la Imagen Aquí";
const buttonTextInnerBottom = "Sube una Foto";
const buttonTextStep2 = "Volver a las Preguntas";

export const Step2 = ({
  stepsSwitch,
  setStepsSwitch,
  setSelectedImage,
  setImageAwsId,
}: Step2Props) => {
  const navigate = useNavigate();
  const imageRef = useRef<HTMLInputElement>(null);
  const [dragOver, setDragOver] = React.useState(false);

  // File input is hidden
  // Therefore create an action on the visible button to trigger file chooser according to the OS
  const handleImageUploadAction = (e) => {
    const name = e.target.innerText.replaceAll(" ", "-")
    fireGaEvent(window, "clickEvent", `step-2_${name}`, 25);
    imageRef?.current?.click();
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    /** 
     * Fetches AWS signed URL and uploads image to AWS bucket asynchronously
     * As soon as the File is selected, user is moved onto the next UI step
     * If any errors occur with the upload sequence, we can try again prior to submission of the full Dig payload in the final step
     */
    e.preventDefault();
    if (!e.target.files) {
      return;
    }

    try {
      // Note: We rely on imageIdState to handle any errors during upload sequence
      // If it succeeds, state is set
      // If it fails, state will be empty and we'll use that to retry just before sending the full Dig payload
      // Thus, no need for an else statement below
      const imageFile = e.target.files[0];
      setSelectedImage(imageFile);
      setStepsSwitch("Step3");
      const uploadResult = await getImageUploadLocation(imageFile);
      const { imageId } = uploadResult;

      if (imageId) {
        fireGaEvent(window, "formSubmit", "step-2_photo_successfully_uploaded", 25);
        setImageAwsId(imageId);
      }

    } catch (error: unknown) {
      console.log(error);
      throw new Error("Error calling Dig API");
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  // Primary function to save info to state and move the user forward a step
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Dropped");
    setDragOver(false);

    const f = e.dataTransfer;
    const imageFile = f.files[0];
    setSelectedImage(imageFile);
    setStepsSwitch("Step3");
  };

  // Upload a new image
  const buttonHandlerBack = () => {
    fireGaEvent(window, "clickEvent", "step-2_backToStep1", 15);
    setStepsSwitch("Step1");
  };

  return (
    <div className="bg-peach px-4 py-6 lg:px-[300px] lg:py-12">
      <div
        id="drag-drop-container"
        className="flex flex-col justify-center border-[3.7px] border-dotted border-primary px-4 py-14 lg:pt-12"
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        style={dragOver ? { borderColor: "secondary", backgroundColor: "#ffffff" } : {}}
      >
        <div className="flex justify-center">
          <img className="" src={iconUpload} alt="Upload icon" />
        </div>
        <div className="flex flex-col items-center justify-center gap-y-4 pt-6">
          <div>
            <input
              id="image-input"
              ref={imageRef}
              name="image-upload"
              className="hidden"
              type="file"
              accept="image/*;capture=camera"
              onChange={handleImageUpload}
            />
            <label htmlFor="image-upload" className="hidden">
              Upload an Image
            </label>
            <Button
              className="block lg:hidden"
              variant="secondary"
              type="button"
              text={buttonTextInnerTop}
              onClick={handleImageUploadAction}
            />
            <span className="hidden text-2xl font-bold lg:block">{buttonTextInnerTopDesktop}</span>
          </div>
          <div className="text-center font-bold lg:text-[17px]">- o -</div>
          <Button
            variant="primary"
            type="button"
            text={buttonTextInnerBottom}
            onClick={handleImageUploadAction}
          />
        </div>
      </div>

      <div className="pt-6 text-center text-body-mobile-small lg:mt-4 lg:pt-2.5 lg:text-body-desktop-small">
        {textSubFooter}
      </div>

      <div className="mt-6 w-full text-center lg:mt-[92px]">
        <Button
          variant="secondary"
          type="button"
          icon="chevron"
          iconDirection="left"
          text={buttonTextStep2}
          onClick={buttonHandlerBack}
        />
      </div>
    </div>
  );
};
