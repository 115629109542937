import React from "react";
import { Hero } from "../../components/Hero/Hero";
import { Divider } from "../../components/Divider/Divider";
import { Steps } from "../../components/Steps/Steps";
import { hero } from "../../utils/constants/es-PE/all";
import { AnalysisStateProps } from "../Props";

const { heading, description } = hero;

export const Home = ({ analysis, setAnalysis }: AnalysisStateProps) => (
  <>
    <Hero heading={heading} description={description} />
    <Divider />
    <Steps analysis={analysis} setAnalysis={setAnalysis} />
  </>
);
