import React, { FunctionComponent } from "react";

export const Checkmark: FunctionComponent = () => (
  <svg
    className="h-full w-full"
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1575_60951)">
      <path
        fill="currentColor"
        d="M45 22.5C45 28.4674 42.6295 34.1903 38.4099 38.4099C34.1903 42.6295 28.4674 45 22.5 45C16.5326 45 10.8097 42.6295 6.5901 38.4099C2.37053 34.1903 0 28.4674 0 22.5C0 16.5326 2.37053 10.8097 6.5901 6.5901C10.8097 2.37053 16.5326 0 22.5 0C28.4674 0 34.1903 2.37053 38.4099 6.5901C42.6295 10.8097 45 16.5326 45 22.5ZM33.8344 13.9781C33.6335 13.7779 33.3943 13.6203 33.1311 13.5147C32.8678 13.409 32.586 13.3576 32.3025 13.3633C32.0189 13.3691 31.7394 13.432 31.4807 13.5483C31.222 13.6645 30.9895 13.8318 30.7969 14.04L21.0291 26.4853L15.1425 20.5959C14.7426 20.2233 14.2138 20.0205 13.6673 20.0301C13.1208 20.0398 12.5994 20.2612 12.2129 20.6476C11.8265 21.0341 11.6051 21.5555 11.5954 22.102C11.5858 22.6484 11.7886 23.1773 12.1613 23.5772L19.6031 31.0219C19.8036 31.222 20.0423 31.3797 20.3051 31.4855C20.5678 31.5914 20.8492 31.6432 21.1324 31.638C21.4156 31.6327 21.6949 31.5705 21.9535 31.455C22.2122 31.3395 22.4449 31.173 22.6378 30.9656L33.8653 16.9312C34.2481 16.5333 34.4595 16.0011 34.4543 15.4489C34.449 14.8968 34.2275 14.3687 33.8372 13.9781H33.8344Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1575_60951">
        <rect width="45" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
