import { colorMap } from "./colorMap";

export interface GetHexColorProps {
  key: string;
  selector: "bg" | "border" | "text";
}

export const getHexColorTw = ({ key, selector }: GetHexColorProps) => {
  const { hex } = colorMap.find((color) => color.id === key) ?? { hex: "" };
  const twClassName = `${selector}-[${hex}]`;
  console.log(`Class: ${twClassName}`);
  return twClassName;
};

export const getHexColor = (key: string) => {
  const { hex } = colorMap.find((color) => color.id === key) ?? { hex: "" };
  return hex;
};
