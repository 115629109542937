import React, { useState } from "react";
import { fireGaEvent } from "../../utils/helpers/analytics"

interface ExpandableTextProps {
  targetLength: number;
  fontClasses: string;
  text: string;
  onClick?: () => void;
}

export const ExpandableText = ({ text, fontClasses, targetLength, onClick }: ExpandableTextProps) => {
  const fullTextLength = text.length;
  const shortenedText = text.slice(0, targetLength);
  const isNeeded = fullTextLength >= targetLength;

  // Set the initial state of the text to be collapsed
  const [isExpanded, setIsExpanded] = useState(false);

  // This function is called when the read more/less button is clicked
  const toggleText = () => {
    fireGaEvent(window, "clickEvent", "results_emoji-body-content-lee-mas", 10)
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`${fontClasses} inline-block`}>
      {!isNeeded && text}
      {isNeeded && (
        <>
          <span>{isExpanded ? text : `${shortenedText}...`}</span>
          <span onClick={toggleText} className="toggle-button cursor-auto text-primary">
            {isExpanded ? "" : "(lee más)"}
          </span>
        </>
      )}
    </div>
  );
};
