import React from "react";
import parser from "html-react-parser";

interface NotFoundProps {
  children?: JSX.Element;
}

export const NotFound = ({...props}: NotFoundProps) => (
  <div className="">
    404 Not Found
  </div>
);
