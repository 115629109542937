import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { FormLabel } from "../../FormLabel/FormLabel";
import { FormError } from "../../FormError/FormError";
import { Button } from "../../Button/Button";
import { textEnglishToSpanish } from "../../../utils/helpers/translate";
import { capitalize } from "../../../utils/helpers/capitalize";
import { rgbToObj } from "../../../utils/helpers/rgbToObj";
import { submitForDigAnalysis } from "../../../pages/functions";
import { fireGaEvent } from "../../../utils/helpers/analytics";
import { Step3ConfirmProps, AnalysisSubmitProps, AnalysisReturnProps } from "../../../pages/Props";

const textEmail = "*Correo Electrónico";
const inputEmailPlaceholder = "Ingresa tu correo electrónico";
const textParentName = "*Nombre de mamá o papá";
const textParentNameLast = "*Apellido de mamá o papá";
const inputPlaceholderParentNameFirst = "Nombre";
const inputPlaceholderParentNameLast = "Apellido";
const textCheckbox =
  "*Acepto recibir información sobre promociones exclusivas, ofertas y productos de Huggies® y otras marcas de Kimberly-Clark en función de la información (incluída la información médica) que envie.";
const buttonText = "Mis Resultados";
const buttonTextSecondary = "Volver";

const errorTextEmail = "Ingresa una dirección de correo electrónico válida";
const errorTextFirstName = "Por favor ingresa tu primer nombre";
const errorTextLastName = "Por favor ingresa tu apellido";
const errorTextTerms = "Por favor acepta los términos para avanzar";

const schema = yup
  .object({
    accountEmail: yup.string().required(errorTextEmail),
    firstName: yup.string().required(errorTextFirstName),
    lastName: yup.string().required(errorTextLastName),
    termsAndConditions2: yup
      .boolean()
      .isTrue(errorTextTerms)
      .required(),
  })
  .required();

const initialValues = {
  accountEmail: "",
  firstName: "",
  lastName: "",
  termsAndConditions2: false,
}

export const Step3Confirm = ({
  utm,
  stepsSwitch,
  setStepsSwitch,
  step1FormData,
  doesNotHaveImage,
  selectedImage,
  imageAwsId,
  step3FormData,
  setAnalysis,
  step3ConfirmFormData,
  setStep3ConfirmFormData
}: Step3ConfirmProps) => {
  const navigate = useNavigate();
  const buttonHandlerBack = () => {
    if (doesNotHaveImage) {
      setStepsSwitch("Step2NoImage")
    } else {
      setStepsSwitch("Step3");
    }
  };

  const buttonHandlerLastStep = async (data) => {
    const {accountEmail, firstName, lastName, termsAndConditions2} = data;
    // This could be improved by changing the loading message (e.g. Analyzing vs Analyzing your image)
    // Keeping it simple for now by dropping "tu imagen" in both scenarios
    setStepsSwitch("Loading");
    setStep3ConfirmFormData({...data, accountName: `${data.firstName} ${data.lastName}`});

    // Adjust captured form data to match required Dig payload shape
    const meta: AnalysisSubmitProps = {
      // Step 1
      nappyFood: capitalize(step1FormData?.babyDiet),
      petWeight: step1FormData!.babyWeight,
      birthday: step1FormData!.babyBirthday,
      termsAndConditions1: step1FormData!.termsAndConditions1,

      // Step 2 - File uploaded to AWS => ImageId is sent outside meta object.
      // Step 3
      colors: step3FormData?.colors,
      // No Image scenario
      // TODO No Image - ensure colorInput inserted in correct format after Dig API changes finalized
      colorInput: rgbToObj(step3FormData?.colorInput),
      regionOfInterestCoordinates: step3FormData?.regionOfInterestCoordinates,
      roiPreviewImage: step3FormData?.roiPreviewImage,
      consistency: capitalize(step3FormData?.consistency),
      texture: capitalize(step3FormData?.texture),
      
      // Step3Confirm
      accountEmail,
      accountName: `${data.firstName} ${data.lastName}`,
      firstName,
      lastName,
      termsAndConditions2,

      // Misc and legacy
      queryStringParamater: utm ?? "", // Note this is indeed misspelled
      // eslint-disable-next-line @typescript-eslint/naming-convention
      is_terms: true, // Legacy key - OK to ignore
    }

    // Normal workflow (upload an image)
    // imageAwsId will be null until Dig API updates are completed
    if (imageAwsId) {
      const results: AnalysisReturnProps = await submitForDigAnalysis(imageAwsId, meta);
      if (results) {
        fireGaEvent(window, "formSubmit", "step-3_completeForm", 25);
        setAnalysis(results);
        navigate(`/analysis/${imageAwsId}`);
      }
    }

    // No Image scenario 
    // Note step3FormData.colorInput from Step2NoImage has color text string
    // Alt approach: Set No Image-specific state - e.g. extract out of step3FormData object
    if (doesNotHaveImage) {
      // console.log(`About to call Dig with color input: ${step3FormData?.colorInput}`);
      let results: AnalysisReturnProps = await submitForDigAnalysis("", meta);
      if (results) {
        fireGaEvent(window, "formSubmit", "step-3_no-image_completeForm", 25); // TODO Confirm w/ Carlos
        // Set colorPrediction to selected color from Step2NoImage
        // Until Dig API updates are made, the API will not send down a colorPreduction
        const colorPrediction = step3FormData?.colorInput;
        results = {...results, colorPrediction }
        setAnalysis(results);
        // TODO Adjust React path as needed
        navigate(`/resultados-rapidos/${textEnglishToSpanish(step3FormData?.colorInput)}`);
      }
    }

  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit(values) {
      void buttonHandlerLastStep(values)
    },
  })

  return (
    <div className="bg-peach px-4 py-6 lg:px-[300px] lg:py-12">
      <form onSubmit={formik.handleSubmit}>
        {/* Email */}
        <FormLabel text={textEmail} className="pt-6 lg:pt-10" />
        <div className="mt-2 flex w-full flex-row items-center justify-between gap-4 lg:gap-8">
          <input
            type="text"
            id="accountEmail"
            name="accountEmail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onClick={(e) => { fireGaEvent(window, "formChange", `step-3_${e.currentTarget.id}`, 15); }}
            placeholder={inputEmailPlaceholder}
            className="max-full block w-full grow rounded border border-grayscale-3 py-2.5 pt-[9px] pb-2 text-body-mobile-input outline-0 placeholder:text-grayscale-4 focus:border-secondary focus:ring-secondary lg:text-body-desktop"
          />
        </div>
        {formik.touched.accountEmail && formik.errors.accountEmail ? (
          <FormError text={formik.errors.accountEmail} />
        ) : null}

        {/* Parent Name */}
        <div id="parent-name-wrapper" className="flex flex-col lg:flex-row lg:gap-x-4">
          <div id="fname" className="grow">
            <FormLabel htmlFor="firstName" text={textParentName} className="pt-6 lg:pt-10" />
            <div className="mt-2 flex flex-col items-center gap-4 lg:flex-row lg:gap-8">
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={(e) => { fireGaEvent(window, "formChange", `step-3_${e.currentTarget.id}`, 15); }}
                placeholder={inputPlaceholderParentNameFirst}
                className="block w-full grow rounded border border-grayscale-3 py-2.5 pt-[9px] pb-2 text-body-mobile-input outline-0 placeholder:text-grayscale-4 focus:border-secondary focus:ring-secondary lg:text-body-desktop"
              />
            </div>
            {formik.touched.firstName && formik.errors.firstName ? (
              <FormError text={formik.errors.firstName} />
            ) : null}
          </div>
          <div id="lname" className="grow">
            <FormLabel
              htmlFor="lastName"
              text={textParentNameLast}
              className="pt-6 lg:invisible lg:pt-10"
            />
            <div className="mt-2 flex flex-col items-center gap-4 lg:flex-row lg:gap-8">
              <input
                type="text"
                id="lastName"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={(e) => { fireGaEvent(window, "formChange", `step-3_${e.currentTarget.id}`, 15); }}
                placeholder={inputPlaceholderParentNameLast}
                className="max-full block w-full grow rounded border border-grayscale-3 py-2.5 pt-[9px] pb-2 text-body-mobile-input outline-0 placeholder:text-grayscale-4 focus:border-secondary focus:ring-secondary lg:text-body-desktop"
              />
            </div>
            {formik.touched.lastName && formik.errors.lastName ? (
              <FormError text={formik.errors.lastName} />
            ) : null}
          </div>
        </div>

        {/* Disclaimer */}
        <div className="py-8 lg:py-14">
          <div className="flex items-start">
            <input
              id="termsAndConditions2"
              type="checkbox"
              value=""
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="h-4 w-4 border-primary text-primary bg-transparent focus:!ring-0 focus:ring-offset-0"
            />
            <label
              htmlFor="default-checkbox"
              className="ml-2 text-body-mobile-small lg:text-body-desktop-small"
            >
              {textCheckbox}
            </label>
          </div>
          {formik.touched.termsAndConditions2 && formik.errors.termsAndConditions2 ? (
            <FormError className={"pl-6"} text={formik.errors.termsAndConditions2} />
          ) : null}
        </div>

        <div className="flex justify-center gap-x-9 text-center">
          <Button
            variant="secondary"
            type="button"
            icon="chevron"
            iconDirection="left"
            text={buttonTextSecondary}
            onClick={buttonHandlerBack}
          />
          <Button
            variant="primary"
            type="submit"
            text={buttonText}
          />
        </div>
      </form>
    </div>
  );
};
