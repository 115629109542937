declare global {
  interface Window { dataLayer: any[]; }
}

// There is only one possible eventCategory, so no need to pass it in via props. It's hard-coded below.
// The eventAction key is calculated based on event, so no need to pass it in via props.
export const fireGaEvent = (
  window: Window,
  event: "clickEvent" | "formChange" | "formSubmit",
  eventLabel: string,
  value: 10 | 15 | 25,
) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      eventCategory: "registration_form",
      eventAction: event === "clickEvent" ? "click" : event.toLowerCase(),
      eventLabel,
      value
    });
  }
};

/**
 * step-1_<element item name>
 * step-1_<form field>
 * step-1_<button name>
 * step-2_<button name>
 * step-3_photo_submit
 * step-3_<button name>
 * step-3_<form field>
 * step-3_<natural form>
 * results_<button name>
 */

// dataLayer.push({
//   "event": "clickEvent",
//   "eventCategory": "registration form",
//   "eventAction": "click",
//   "eventLabel": "step-1_<element item name>",
//   "value": 10,
// });
