/**
 * Figma does not export svg's with the currentColor attribute
 * Thus, we need to paste the svg below and adjust is so we can later control with css
 */
import React, { FunctionComponent } from "react";

export const RightArrow: FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M14.4688 16.7812C14.75 17.0625 15.2188 17.0625 15.5 16.7812L19.75 12.5312C20.0625 12.2188 20.0625 11.75 19.75 11.4688L15.5 7.21875C15.2188 6.90625 14.75 6.90625 14.4688 7.21875C14.1562 7.5 14.1562 7.96875 14.4688 8.28125L17.4375 11.25H4.75C4.3125 11.25 4 11.5625 4 12C4 12.4062 4.3125 12.75 4.75 12.75H17.4375L14.4688 15.7188C14.3125 15.8438 14.25 16.0625 14.25 16.25C14.25 16.4375 14.3125 16.625 14.4688 16.7812Z"/>
  </svg>
);
