import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errors } from "../../utils/constants/es-PE/all";

const {generalServerError, noImageContingencyRefreshError} = errors;

export const ErrorRedirect = (noImage) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  return (
    <div className="loading">
      <h5 className="loading-text">{noImage ? noImageContingencyRefreshError : generalServerError }</h5>
    </div>
  );
};

export default ErrorRedirect;
